import React, { type FC } from 'react'

import classnames from 'classnames'

import { HullType, LoadStatus, OverviewNominationVersionType, TboSubmissionStatusId } from '../../../generated/graphql'

import LabelValuePair from './LabelValuePair'
import styles from './styles.module.scss'

import type { VersionSummary } from './viewModel'

const headerClass = {
  [OverviewNominationVersionType.Native]: styles.nativeNomination,
  [OverviewNominationVersionType.External]: styles.externalNomination,
}

export const TBOSubmissionStatusLabels: Record<TboSubmissionStatusId, string> = {
  [TboSubmissionStatusId.Failed]: 'Failed',
  [TboSubmissionStatusId.NotSubmitted]: 'Not Submitted',
  [TboSubmissionStatusId.Pending]: 'Pending',
  [TboSubmissionStatusId.Submitted]: 'Submitted',
}

const NominationVersionSummary: FC<{ summary: VersionSummary }> = ({ summary }) => {
  const { nominatedBargeStatistics, tbnBargeStatistics, hotBargeStatistics } = summary

  return (
    <div className={styles.container}>
      <div>
        <div className={classnames([styles.section, styles.header, headerClass[summary.type]])}>
          <LabelValuePair label="Type" value={summary.type} />
        </div>
        <div className={classnames([styles.section, styles.columns])}>
          <div className={styles.column}>
            <LabelValuePair label="Version Creation Time" value={summary.recordTime} />
            <LabelValuePair label="Lane" value={summary.lane} />
            <LabelValuePair label="Origin" value={summary.origin} />
            <LabelValuePair label="Destination" value={summary.destination} />
            <LabelValuePair label="Operation Goal" value={summary.operationalGoal} />
            <LabelValuePair label="Departure Time" value={summary.expectedDepartureDate} />
            <section className={styles.tboStatistics}>
              {summary.type === OverviewNominationVersionType.Native ? (
                <LabelValuePair label="TBO Submission Status" value={summary.tboSubmissionStatus.displayName} />
              ) : (
                <LabelValuePair label="TBO Information" value="" />
              )}
              {summary.tboInfo && (
                <>
                  <LabelValuePair
                    label="Order Number"
                    value={summary.tboInfo.fullOrderNumber}
                    labelStyle={styles.padded}
                  />
                  <LabelValuePair label="Order ID" value={summary.tboInfo.orderNumber} labelStyle={styles.padded} />
                  <LabelValuePair label="Header ID" value={summary.tboInfo.headerId} labelStyle={styles.padded} />
                  <LabelValuePair label="Revision" value={summary.tboInfo.revisionNumber} labelStyle={styles.padded} />
                </>
              )}
            </section>
          </div>
          <div className={styles.column}>
            <LabelValuePair label="Vessel" value={summary.vessel} />
            <LabelValuePair label="Turnboat" value={summary.hasTurnboat} />
            <LabelValuePair label="Total Transit Time" value={summary.transitTime} />
            <LabelValuePair label="Total Dwell time" value={summary.dwellTime} />
            <LabelValuePair label="Total Stops" value={summary.totalStops} />
            <LabelValuePair label="Total Barge Miles" value={summary.totalBargeMiles} />
            <LabelValuePair label="Distinct Barge Destinations" value={summary.totalDestinations} />
            <LabelValuePair label="Tow Score" value={summary.towScore} />
          </div>
          <div className={styles.column}>
            <LabelValuePair label="Total Barges" value={nominatedBargeStatistics.quantity} />
            <LabelValuePair label="Loaded" value={nominatedBargeStatistics.loaded} labelStyle={styles.padded} />
            <LabelValuePair label="Empty" value={nominatedBargeStatistics.empty} labelStyle={styles.padded} />
            <LabelValuePair label="Total Rakes" value={nominatedBargeStatistics.rakes} />
            <LabelValuePair label="Loaded" value={nominatedBargeStatistics.loadedRakes} labelStyle={styles.padded} />
            <LabelValuePair label="Empty" value={nominatedBargeStatistics.emptyRakes} labelStyle={styles.padded} />
            <LabelValuePair label="Total Boxes" value={nominatedBargeStatistics.boxes} />
            <LabelValuePair label="Loaded" value={nominatedBargeStatistics.loadedBoxes} labelStyle={styles.padded} />
            <LabelValuePair label="Empty" value={nominatedBargeStatistics.emptyBoxes} labelStyle={styles.padded} />
          </div>
          <div className={styles.column}>
            <section className={styles.tbnStatistics}>
              <LabelValuePair label="TBN Barges" value={tbnBargeStatistics.quantity} />
              {tbnBargeStatistics.entries.map(entry => (
                <div key={entry.title}>
                  <LabelValuePair label={entry.title} value={entry.quantity} />
                  <LabelValuePair label="Loaded" value={entry[LoadStatus.Loaded]} labelStyle={styles.padded} />
                  <LabelValuePair label="Empty" value={entry[LoadStatus.Empty]} labelStyle={styles.padded} />
                </div>
              ))}
            </section>
            <section className={styles.hotStatistics}>
              <LabelValuePair label="Hot Barges" value={hotBargeStatistics.quantity} />
              <LabelValuePair label="Rakes" value={hotBargeStatistics[HullType.Rake]} labelStyle={styles.padded} />
              <LabelValuePair label="Boxes" value={hotBargeStatistics[HullType.Box]} labelStyle={styles.padded} />
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NominationVersionSummary
