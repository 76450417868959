// THIS IS AUTOGENERATED FILE
/* eslint-disable */

import gql from 'graphql-tag'
import * as Urql from 'urql'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
  BargeId: { input: any; output: any }
  /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
  BoatId: { input: any; output: any }
  /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
  CognitoId: { input: any; output: any }
  /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
  FacilityCode: { input: any; output: any }
  /** The `Long` scalar type represents non-fractional signed whole numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: { input: any; output: any }
  /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
  NominationId: { input: any; output: any }
  /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
  NominationSlug: { input: any; output: any }
  /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
  NominationVersionId: { input: any; output: any }
  /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
  NominationVersionSlug: { input: any; output: any }
  /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
  RiverCode: { input: any; output: any }
  /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
  UUID: { input: any; output: any }
  /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
  ZonedDateTime: { input: any; output: any }
}

export type ArchivingFailure = {
  __typename?: 'ArchivingFailure'
  errors: Array<Scalars['String']['output']>
  message: Scalars['String']['output']
}

export type ArchivingResult = ArchivingFailure | ArchivingSuccess

export type ArchivingSuccess = {
  __typename?: 'ArchivingSuccess'
  message: Scalars['String']['output']
}

export type BargeGroup = {
  __typename?: 'BargeGroup'
  barges: Array<Scalars['BargeId']['output']>
  uuid: Scalars['UUID']['output']
}

export type BargeInPool = {
  __typename?: 'BargeInPool'
  barge: OverviewBarge
  currentLocation: OverviewLocation
  currentTrip: OverviewTrip
  custodyInfo: OverviewCustodyInfo
  dropOffFacility: Maybe<FacilityLocation>
  expectedTripLoadStatus: LoadStatus
  group: Maybe<BargeGroup>
  isHot: Scalars['Boolean']['output']
  lastUpdated: Scalars['ZonedDateTime']['output']
  latestTBO: Maybe<OverviewBargeTbo>
  nextDropOffDetails: Maybe<OverviewDropOffDetails>
  pickupFacility: Maybe<FacilityLocation>
  pickupType: Maybe<PickupType>
  riskLevel: Maybe<RiskLevel>
  status: CustodyStatus
  timeInFleet: Maybe<Scalars['Long']['output']>
}

export type BargeNominationFilters = {
  __typename?: 'BargeNominationFilters'
  excludeBargeIds: Array<Scalars['BargeId']['output']>
  excludeBargeTypes: Array<GraphqlBargeType>
  excludeNonNominatableBarges: Scalars['Boolean']['output']
  excludeShuttleMoves: Scalars['Boolean']['output']
  excludeTboInfoBarges: Scalars['Boolean']['output']
  excludeTripStatuses: Array<TripStatusId>
  expectedDepartureTime: Maybe<Scalars['ZonedDateTime']['output']>
  includeTBOs: Array<Scalars['Long']['output']>
  lane: LaneId
  maximumDraft: Maybe<Scalars['Float']['output']>
  towDestination: HubLikeId
  towOrigin: HubLikeId
}

export type BargeNominationInput = {
  destination: HubLikeId
  excludeBargeIds: InputMaybe<Array<Scalars['BargeId']['input']>>
  excludeBargeTypes: InputMaybe<Array<GraphqlBargeType>>
  excludeShuttleMoves: InputMaybe<Scalars['Boolean']['input']>
  excludeTboInfoBarges: InputMaybe<Scalars['Boolean']['input']>
  excludeTripStatuses: InputMaybe<Array<GraphqlTripStatus>>
  expectedDepartureTime: InputMaybe<Scalars['ZonedDateTime']['input']>
  includeTBOs: InputMaybe<Array<Scalars['Long']['input']>>
  lane: LaneId
  maximumDraft: InputMaybe<Scalars['Float']['input']>
  origin: HubLikeId
  prioritizeHotBarges: InputMaybe<Scalars['Boolean']['input']>
  towConfiguration: TowConfigurationInput
}

export type BargeNominationRequest = {
  __typename?: 'BargeNominationRequest'
  bargeFilters: BargeNominationFilters
  prioritizeHotBarges: Scalars['Boolean']['output']
  towConfiguration: TowConfiguration
}

export type BargesFilter = {
  bargeTypesToExclude: InputMaybe<Array<GraphqlBargeType>>
  destination: InputMaybe<HubLikeId>
  excludeShuttleMoves: InputMaybe<Scalars['Boolean']['input']>
  excludeTboInfoBarges: InputMaybe<Scalars['Boolean']['input']>
  expectedDepartureTime: InputMaybe<Scalars['ZonedDateTime']['input']>
  includeBargeIds: InputMaybe<Array<Scalars['BargeId']['input']>>
  includeTBOs: InputMaybe<Array<Scalars['Long']['input']>>
  maximumDraft: InputMaybe<Scalars['Float']['input']>
  origin: InputMaybe<HubLikeId>
  tripStatusesToExclude: InputMaybe<Array<GraphqlTripStatus>>
}

export type ChangedRiskLevel = {
  __typename?: 'ChangedRiskLevel'
  bargeId: Scalars['BargeId']['output']
  newRiskLevel: RiskLevel
}

export type CreationFailure = {
  __typename?: 'CreationFailure'
  errors: Array<Scalars['String']['output']>
  message: Scalars['String']['output']
}

export type CreationSuccess = {
  __typename?: 'CreationSuccess'
  nominationId: Scalars['NominationId']['output']
  nominationVersionId: Scalars['NominationVersionId']['output']
}

export type CrossEfficiencyGoalScore = {
  __typename?: 'CrossEfficiencyGoalScore'
  goal: GoalId
  score: Scalars['Float']['output']
}

export type CustodyFacility = {
  __typename?: 'CustodyFacility'
  name: Scalars['String']['output']
}

export enum CustodyStatus {
  InFleet = 'InFleet',
  InTow = 'InTow',
}

export type FacilityLocation = {
  __typename?: 'FacilityLocation'
  code: Scalars['RiverCode']['output']
  facilityCode: Maybe<Scalars['FacilityCode']['output']>
  mileagePoint: Scalars['Float']['output']
  name: Maybe<Scalars['String']['output']>
}

export type Fleet = {
  __typename?: 'Fleet'
  name: Maybe<Scalars['String']['output']>
}

export type Goal = {
  __typename?: 'Goal'
  description: Scalars['String']['output']
  id: GoalId
  label: Scalars['String']['output']
}

export enum GoalId {
  BuildToDestination = 'BuildToDestination',
  FleetEfficiency = 'FleetEfficiency',
  LinehaulTurnTime = 'LinehaulTurnTime',
  ShortDropTow = 'ShortDropTow',
}

export enum GraphqlBargeType {
  DeckOpen = 'DeckOpen',
  HoppOpen = 'HoppOpen',
  HoppSpread = 'HoppSpread',
  HoppStacked = 'HoppStacked',
  TankCaustic = 'TankCaustic',
  TankChemical = 'TankChemical',
  TankFuel = 'TankFuel',
  Unknown = 'Unknown',
}

export type GraphqlNewNominationRequest = {
  configuration: VersionedTowConfigurationInput
  filters: VersionedNominationFiltersInput
}

export enum GraphqlTripStatus {
  ArrivedForDischarge = 'ArrivedForDischarge',
  ArrivedForLoad = 'ArrivedForLoad',
  Canceled = 'Canceled',
  CanceledDestocking = 'CanceledDestocking',
  CanceledEmpty = 'CanceledEmpty',
  CanceledTramp = 'CanceledTramp',
  Destocking = 'Destocking',
  EmptyMove = 'EmptyMove',
  EmptyTramp = 'EmptyTramp',
  FinishedDischarge = 'FinishedDischarge',
  FinishedLoad = 'FinishedLoad',
  FinishedPartialDischarge = 'FinishedPartialDischarge',
  FinishedPartialLoad = 'FinishedPartialLoad',
  IntermediateOffPlacement = 'IntermediateOffPlacement',
  IntermediateOnPlacement = 'IntermediateOnPlacement',
  LoadedTramp = 'LoadedTramp',
  OnEmptyStorage = 'OnEmptyStorage',
  OnStorage = 'OnStorage',
  Pending = 'Pending',
  PlacedDischarge = 'PlacedDischarge',
  PlacedLoad = 'PlacedLoad',
  PlacedPartialDischarge = 'PlacedPartialDischarge',
  PlacedPartialLoad = 'PlacedPartialLoad',
  Planned = 'Planned',
  PlannedEmpty = 'PlannedEmpty',
  TrampArrivedForDischarge = 'TrampArrivedForDischarge',
  TrampArrivedForLoad = 'TrampArrivedForLoad',
  TrampFinishedDischarge = 'TrampFinishedDischarge',
  TrampFinishedLoad = 'TrampFinishedLoad',
  TrampFinishedPartialDischarge = 'TrampFinishedPartialDischarge',
  TrampFinishedPartialLoad = 'TrampFinishedPartialLoad',
  TrampPlacedDischarge = 'TrampPlacedDischarge',
  TrampPlacedLoad = 'TrampPlacedLoad',
  TrampPlacedPartialDischarge = 'TrampPlacedPartialDischarge',
  TrampPlacedPartialLoad = 'TrampPlacedPartialLoad',
  Transfer = 'Transfer',
}

export type GraphqlVersionRequest = {
  configuration: VersionedTowConfigurationInput
  filters: VersionedNominationFiltersInput
  nominationId: Scalars['NominationId']['input']
}

export type Hub = {
  __typename?: 'Hub'
  id: HubLikeId
  label: Scalars['String']['output']
  riverLocation: RiverLocationLite
}

export type HubLike = Hub | NonHub

export enum HubLikeId {
  Acbl = 'ACBL',
  ArtcoLasalle = 'ArtcoLasalle',
  ArtcoMorris = 'ArtcoMorris',
  ArtcoOttawa = 'ArtcoOttawa',
  ArtcoPekin = 'ArtcoPekin',
  ArtcoPeoria = 'ArtcoPeoria',
  ArtcoSeneca = 'ArtcoSeneca',
  BatonRouge = 'BatonRouge',
  BellaireHarborService = 'BellaireHarborService',
  BelleChasseCooper = 'BelleChasseCooper',
  BrennanFleet = 'BrennanFleet',
  CcNucorFleet = 'CCNucorFleet',
  CgbWaterfront = 'CGBWaterfront',
  ChsMyrtleGrove = 'CHSMyrtleGrove',
  CalvertCityTva = 'CalvertCityTva',
  CantonFleet = 'CantonFleet',
  CapeFleeting = 'CapeFleeting',
  CarlineBurnsideFleet = 'CarlineBurnsideFleet',
  ClaytonTowing = 'ClaytonTowing',
  ClintonArtcoFleet = 'ClintonArtcoFleet',
  Cooper164 = 'Cooper164',
  CoraFleet = 'CoraFleet',
  CumberlandCityStone = 'CumberlandCityStone',
  CumberlandRiverQuarry = 'CumberlandRiverQuarry',
  Dairyland = 'Dairyland',
  DarrowFleet = 'DarrowFleet',
  DomtarHawesvilleMill = 'DomtarHawesvilleMill',
  DubuqueArtcoFleet = 'DubuqueArtcoFleet',
  EddyvilleGavilon = 'EddyvilleGavilon',
  Evansville795Fleet = 'Evansville795Fleet',
  GrmsColumbus = 'GRMSColumbus',
  GnotsDestrehan = 'GnotsDestrehan',
  Hall = 'Hall',
  Hannibal = 'Hannibal',
  HaverhillMcGinnis = 'HaverhillMcGinnis',
  Hebron = 'Hebron',
  HendersonHarborService = 'HendersonHarborService',
  HerculaneumFleet = 'HerculaneumFleet',
  HinesFurlongClarksville = 'HinesFurlongClarksville',
  HolcimStGenevieveFleet = 'HolcimStGenevieveFleet',
  HueyPLongFleet = 'HueyPLongFleet',
  ImtChannahon = 'IMTChannahon',
  ImtFleet = 'IMTFleet',
  ImtLemont = 'IMTLemont',
  IllinoisMarine = 'IllinoisMarine',
  IndustryTerminalSalvageFleet = 'IndustryTerminalSalvageFleet',
  Ingram184Nashville = 'Ingram184Nashville',
  InmanTowing = 'InmanTowing',
  JohnsonFleet = 'JohnsonFleet',
  Keokuk = 'Keokuk',
  LacrosseBrennan = 'LacrosseBrennan',
  LewisClarkBuzziFestus = 'LewisClarkBuzziFestus',
  LogsdonBeardstown = 'LogsdonBeardstown',
  LogsdonMerdosia = 'LogsdonMerdosia',
  LoneEagleFleet = 'LoneEagleFleet',
  MvbsJeffersonvilleFleet = 'MVBSJeffersonvilleFleet',
  MattesonMarineBurlington = 'MattesonMarineBurlington',
  MattesonMarineMeekers = 'MattesonMarineMeekers',
  Maysville = 'Maysville',
  McBride5M = 'McBride5M',
  McGinWhlrsburg = 'McGinWhlrsburg',
  McGregorArtcoFleet = 'McGregorArtcoFleet',
  McKeesRocksIndustrialFleet = 'McKeesRocksIndustrialFleet',
  MertelsPeru = 'MertelsPeru',
  Metropolis = 'Metropolis',
  MeyersFleet = 'MeyersFleet',
  MillCreek = 'MillCreek',
  MtVernonFleet = 'MtVernonFleet',
  NealesTowingCo = 'NealesTowingCo',
  NorthAmericanStainless = 'NorthAmericanStainless',
  NucorGallatin = 'NucorGallatin',
  OsageHennepinFleet = 'OsageHennepinFleet',
  OsageMarineNaples = 'OsageMarineNaples',
  OsageMarineSpringValley = 'OsageMarineSpringValley',
  Owensboro750Fleet = 'Owensboro750Fleet',
  Owensboro759Fleet = 'Owensboro759Fleet',
  OzingaHenryFleet = 'OzingaHenryFleet',
  Paducah = 'Paducah',
  PearsonFranks = 'PearsonFranks',
  PleasantPower = 'PleasantPower',
  PortOfLouisville = 'PortOfLouisville',
  PtPleasantMadison = 'PtPleasantMadison',
  Quincy = 'Quincy',
  RrrGrundy = 'RRRGrundy',
  ReverseTriangle = 'ReverseTriangle',
  Riverview = 'Riverview',
  Rosiclaire = 'Rosiclaire',
  ShawneetownFleet = 'ShawneetownFleet',
  SilverGrove = 'SilverGrove',
  SitranDock = 'SitranDock',
  SoIllTransferJimKennedy = 'SoIllTransferJimKennedy',
  Spurlock = 'Spurlock',
  StJohnFleeting = 'StJohnFleeting',
  StLouis = 'StLouis',
  SuperiorMarine = 'SuperiorMarine',
  Tanner = 'Tanner',
  TellcityTugSv = 'TellcityTugSV',
  ThreeRiversQuarry = 'ThreeRiversQuarry',
  TrimbleCounty = 'TrimbleCounty',
  Trumbul = 'Trumbul',
  TunnelRidgeTerminalWheeling = 'TunnelRidgeTerminalWheeling',
  TurnServices = 'TurnServices',
  TurnServicesDockside = 'TurnServicesDockside',
  TurnServicesMyrtleGrove = 'TurnServicesMyrtleGrove',
  TvaGallatin = 'TvaGallatin',
  UnitedBulkTerminal = 'UnitedBulkTerminal',
  UpperMissFleeting = 'UpperMissFleeting',
  UpperRiverSvs = 'UpperRiverSvs',
  ValleyTerminalFleet = 'ValleyTerminalFleet',
  WeberMarine = 'WeberMarine',
  WinonaArtcoFleet = 'WinonaArtcoFleet',
  YankeetownFleet = 'YankeetownFleet',
}

export enum HullType {
  Box = 'Box',
  Rake = 'Rake',
}

export type Lane = {
  __typename?: 'Lane'
  barges: Array<BargeInPool>
  id: LaneId
  label: Scalars['String']['output']
}

export type LaneBargesArgs = {
  bargesFilter: BargesFilter
}

export enum LaneId {
  EbOhio = 'EBOhio',
  Nbcum = 'NBCUM',
  NbIllinois = 'NBIllinois',
  Nbumr = 'NBUMR',
  NbUnlocked = 'NBUnlocked',
  NbUnlockedDirect = 'NBUnlockedDirect',
  NbUnlockedDirectStl = 'NBUnlockedDirectSTL',
  Sbcum = 'SBCUM',
  SbIllinois = 'SBIllinois',
  Sbumr = 'SBUMR',
  SbUnlocked = 'SBUnlocked',
  SbUnlockedDirect = 'SBUnlockedDirect',
  SbUnlockedDirectStl = 'SBUnlockedDirectSTL',
  WbOhio = 'WBOhio',
}

export type LatestNominationVersionSummary = {
  __typename?: 'LatestNominationVersionSummary'
  destination: Maybe<FacilityLocation>
  expectedDepartureTime: Maybe<Scalars['ZonedDateTime']['output']>
  id: Scalars['NominationId']['output']
  lane: Maybe<LaneId>
  origin: Maybe<FacilityLocation>
  owner: Maybe<Owner>
  recordTime: Scalars['ZonedDateTime']['output']
  tboLinkStatus: TboLinkStatus
  tboNumber: Maybe<Scalars['Long']['output']>
  towShape: Maybe<TowShape>
}

export enum LoadStatus {
  Empty = 'Empty',
  Loaded = 'Loaded',
}

export type Mutation = {
  __typename?: 'Mutation'
  archiveNomination: ArchivingResult
  archiveNominationVersion: ArchivingResult
  createBargeNomination: NominationCreateResult
  createVersion: NominationVersionCreationResult
  createVersionedNomination: NominationVersionCreationResult
  deleteBargeNomination: NominationDeleteSuccess
  replaceNominationBarges: NominationReplaceBargesResult
  saveBargeNomination: NominationSaveSuccess
  submitBargeNomination: NominationSubmitSuccess
  submitVersionToTbo: SubmissionResult
  updateBargeNomination: NominationUpdateSuccess
}

export type MutationArchiveNominationArgs = {
  nominationId: Scalars['NominationId']['input']
}

export type MutationArchiveNominationVersionArgs = {
  nominationId: Scalars['NominationId']['input']
  nominationVersionId: Scalars['NominationVersionId']['input']
}

export type MutationCreateBargeNominationArgs = {
  bargeNominationInput: BargeNominationInput
}

export type MutationCreateVersionArgs = {
  versionRequest: GraphqlVersionRequest
}

export type MutationCreateVersionedNominationArgs = {
  newNominationRequest: GraphqlNewNominationRequest
}

export type MutationDeleteBargeNominationArgs = {
  nominationId: Scalars['NominationId']['input']
}

export type MutationReplaceNominationBargesArgs = {
  nominationId: Scalars['NominationId']['input']
}

export type MutationSaveBargeNominationArgs = {
  nominationId: Scalars['NominationId']['input']
}

export type MutationSubmitBargeNominationArgs = {
  nominationId: Scalars['NominationId']['input']
}

export type MutationSubmitVersionToTboArgs = {
  nominationId: Scalars['NominationId']['input']
  nominationVersionId: Scalars['NominationVersionId']['input']
}

export type MutationUpdateBargeNominationArgs = {
  latestBargeNominationId: Scalars['NominationId']['input']
  nominationId: Scalars['NominationId']['input']
}

export type NominatableBoat = {
  __typename?: 'NominatableBoat'
  abbreviation: Maybe<Scalars['String']['output']>
  boatId: Scalars['BoatId']['output']
  name: Maybe<Scalars['String']['output']>
}

export type NominatedBarge = {
  __typename?: 'NominatedBarge'
  cargo: Maybe<Scalars['String']['output']>
  currentLocation: Maybe<RiverLocationLite>
  destination: Maybe<RiverLocationLite>
  distanceToPickup: Maybe<Scalars['Float']['output']>
  dropOffFacility: FacilityLocation
  expectedLoadStatus: LoadStatus
  fleet: Maybe<Fleet>
  hullType: Maybe<HullType>
  id: Scalars['BargeId']['output']
  isHot: Scalars['Boolean']['output']
  isPreselected: Scalars['Boolean']['output']
  isScheduledForPickup: Scalars['Boolean']['output']
  loadStatus: LoadStatus
  name: Maybe<Scalars['String']['output']>
  pickupFacility: FacilityLocation
  pickupType: PickupType
  riskLevel: RiskLevel
  towBuildOrder: Maybe<TowBuildOrder>
  tripStatus: TripStatusId
  type: GraphqlBargeType
}

export type NominatedTbnBarge = {
  __typename?: 'NominatedTBNBarge'
  dropOffFacility: FacilityLocation
  expectedLoadStatus: LoadStatus
  pickupFacility: FacilityLocation
}

export type NominatedTow = {
  __typename?: 'NominatedTow'
  barges: Array<NominatedBarge>
  boat: NominatableBoat
  efficiencyMetric: Maybe<Scalars['Float']['output']>
  hasTurnboat: Scalars['Boolean']['output']
  stopsWithMetrics: Array<StopWithMetrics>
  tbnBarges: Array<NominatedTbnBarge>
}

export type NominationCreateFailure = {
  __typename?: 'NominationCreateFailure'
  errors: Array<Scalars['String']['output']>
  message: Scalars['String']['output']
}

export type NominationCreateResult = NominationCreateFailure | NominationCreateSuccess

export type NominationCreateSuccess = {
  __typename?: 'NominationCreateSuccess'
  nomination: UserBargeNomination
}

export type NominationDeleteSuccess = {
  __typename?: 'NominationDeleteSuccess'
  nominationId: Scalars['NominationId']['output']
}

export type NominationReplaceBargesFailure = {
  __typename?: 'NominationReplaceBargesFailure'
  errors: Array<Scalars['String']['output']>
  message: Scalars['String']['output']
}

export type NominationReplaceBargesResult = NominationReplaceBargesFailure | NominationReplaceBargesSuccess

export type NominationReplaceBargesSuccess = {
  __typename?: 'NominationReplaceBargesSuccess'
  nomination: UserBargeNomination
}

export type NominationReview = {
  __typename?: 'NominationReview'
  changedRiskLevel: Array<ChangedRiskLevel>
  leftTheBargePool: Array<Scalars['BargeId']['output']>
  receivedTBO: Array<ReceivedTbo>
}

export type NominationSaveSuccess = {
  __typename?: 'NominationSaveSuccess'
  nominationId: Scalars['NominationId']['output']
}

export type NominationSubmitSuccess = {
  __typename?: 'NominationSubmitSuccess'
  nominationId: Scalars['NominationId']['output']
  tboSubmissionStatus: TboSubmissionStatusId
}

export type NominationUpdateSuccess = {
  __typename?: 'NominationUpdateSuccess'
  nominationId: Scalars['NominationId']['output']
}

export type NominationVersionCreationResult = CreationFailure | CreationSuccess

export type NonHub = {
  __typename?: 'NonHub'
  id: HubLikeId
  label: Scalars['String']['output']
  riverLocation: RiverLocationLite
}

export type OrderRevisionId = {
  __typename?: 'OrderRevisionId'
  headerId: Scalars['Long']['output']
  number: Scalars['Long']['output']
  revisionNumber: Scalars['Int']['output']
}

export type OverviewBarge = {
  __typename?: 'OverviewBarge'
  hullType: Maybe<HullType>
  id: Scalars['BargeId']['output']
  name: Maybe<Scalars['String']['output']>
  type: GraphqlBargeType
}

export type OverviewBargeTbo = {
  __typename?: 'OverviewBargeTBO'
  dropFacility: Maybe<Scalars['String']['output']>
  info: Maybe<Scalars['String']['output']>
}

export type OverviewCustodyBoat = {
  __typename?: 'OverviewCustodyBoat'
  activeOrder: Maybe<OrderRevisionId>
  destination: Maybe<FacilityLocation>
  eta: Maybe<Scalars['ZonedDateTime']['output']>
  name: Maybe<Scalars['String']['output']>
}

export type OverviewCustodyInfo = {
  __typename?: 'OverviewCustodyInfo'
  boat: Maybe<OverviewCustodyBoat>
  custody: Scalars['String']['output']
  facility: Maybe<CustodyFacility>
  fleet: Maybe<Fleet>
}

export type OverviewDropOffDetails = {
  __typename?: 'OverviewDropOffDetails'
  estimatedDropOffTime: Maybe<Scalars['ZonedDateTime']['output']>
  facility: FacilityLocation
  willBeDroppedAtNextStop: Scalars['Boolean']['output']
}

export type OverviewLocation = {
  __typename?: 'OverviewLocation'
  locationCode: Maybe<Scalars['String']['output']>
  riverLocation: RiverLocationLite
}

export type OverviewNomination = {
  __typename?: 'OverviewNomination'
  id: Scalars['NominationId']['output']
  ownerId: Maybe<Scalars['CognitoId']['output']>
  recordTime: Scalars['ZonedDateTime']['output']
  slug: Scalars['NominationSlug']['output']
  tboLinkStatus: TboLinkStatus
  tboNumber: Maybe<Scalars['Long']['output']>
}

export type OverviewNominationVersion = {
  __typename?: 'OverviewNominationVersion'
  crossEfficiencyGoalScores: Maybe<Array<CrossEfficiencyGoalScore>>
  id: Scalars['NominationVersionId']['output']
  nominationId: Scalars['NominationId']['output']
  nominationRequest: Maybe<OverviewVersionedNominationRequest>
  orderRevisionId: Maybe<OrderRevisionId>
  owner: Maybe<Owner>
  recordTime: Scalars['ZonedDateTime']['output']
  review: Maybe<NominationReview>
  slug: Scalars['NominationVersionSlug']['output']
  tboSubmissionStatus: Maybe<TboSubmissionStatusId>
  tow: NominatedTow
  type: OverviewNominationVersionType
}

export enum OverviewNominationVersionType {
  External = 'External',
  Native = 'Native',
}

export type OverviewNominationWithVersions = {
  __typename?: 'OverviewNominationWithVersions'
  nomination: OverviewNomination
  versions: Array<OverviewNominationVersion>
}

export type OverviewTrip = {
  __typename?: 'OverviewTrip'
  actualDraft: Maybe<Scalars['Int']['output']>
  cargo: Maybe<Scalars['String']['output']>
  destination: Maybe<FacilityLocation>
  loadStatus: LoadStatus
  origin: Maybe<FacilityLocation>
  startTime: Maybe<Scalars['ZonedDateTime']['output']>
  status: TripStatusId
  tripStatusTime: Maybe<Scalars['ZonedDateTime']['output']>
}

export type OverviewVersionedNominationRequest = {
  __typename?: 'OverviewVersionedNominationRequest'
  configuration: VersionedTowConfiguration
  filters: VersionedNominationFilters
}

export enum OwnedBy {
  Everyone = 'Everyone',
  Me = 'Me',
  Tbo = 'TBO',
}

export type Owner = {
  __typename?: 'Owner'
  cognitoId: Scalars['CognitoId']['output']
  username: Scalars['String']['output']
}

export enum PickupType {
  Consolidated = 'Consolidated',
  Simple = 'Simple',
}

export type Query = {
  __typename?: 'Query'
  boats: Array<NominatableBoat>
  goals: Array<Goal>
  hubLikes: Array<HubLike>
  lanes: Array<Lane>
  nominationById: Maybe<OverviewNominationWithVersions>
  nominations: Array<LatestNominationVersionSummary>
  savedBargeNomination: Maybe<SavedBargeNomination>
  savedBargeNominations: Array<SavedBargeNomination>
  tboSubmissionStatuses: Array<TboSubmissionStatus>
  tripStatuses: Array<TripStatus>
}

export type QueryBoatsArgs = {
  boatDestination: InputMaybe<HubLikeId>
}

export type QueryLanesArgs = {
  laneId: InputMaybe<LaneId>
}

export type QueryNominationByIdArgs = {
  nominationId: Scalars['NominationId']['input']
}

export type QueryNominationsArgs = {
  ownedBy: InputMaybe<OwnedBy>
}

export type QuerySavedBargeNominationArgs = {
  nominationId: Scalars['NominationId']['input']
}

export type ReceivedTbo = {
  __typename?: 'ReceivedTBO'
  bargeId: Scalars['BargeId']['output']
  tbo: Scalars['String']['output']
}

export enum RiskLevel {
  HighRisk = 'HighRisk',
  LowRisk = 'LowRisk',
  MediumRisk = 'MediumRisk',
  NoRisk = 'NoRisk',
  UnknownRisk = 'UnknownRisk',
}

export type RiverLocationLite = {
  __typename?: 'RiverLocationLite'
  code: Scalars['RiverCode']['output']
  mileagePoint: Scalars['Float']['output']
}

export type SavedBargeNomination = {
  __typename?: 'SavedBargeNomination'
  nomination: UserBargeNomination
  review: NominationReview
  tboSubmissionStatus: TboSubmissionStatusId
}

export type StopWithMetrics = {
  __typename?: 'StopWithMetrics'
  bargeMilesMetric: Maybe<Scalars['Float']['output']>
  distanceToNextStop: Maybe<Scalars['Float']['output']>
  dwellMinutes: Maybe<Scalars['Long']['output']>
  stop: RiverLocationLite
  travelMinutesToNextStop: Maybe<Scalars['Long']['output']>
}

export type SubmissionResult = SubmitFailure | SubmitSuccess

export type SubmitFailure = {
  __typename?: 'SubmitFailure'
  message: Scalars['String']['output']
}

export type SubmitSuccess = {
  __typename?: 'SubmitSuccess'
  nominationId: Scalars['NominationId']['output']
  nominationVersionId: Scalars['NominationVersionId']['output']
}

export enum TboLinkStatus {
  Broken = 'Broken',
  Established = 'Established',
  NotEstablished = 'NotEstablished',
}

export type TboSubmissionStatus = {
  __typename?: 'TBOSubmissionStatus'
  displayName: Scalars['String']['output']
  id: TboSubmissionStatusId
}

export enum TboSubmissionStatusId {
  Failed = 'Failed',
  NotSubmitted = 'NotSubmitted',
  Pending = 'Pending',
  Submitted = 'Submitted',
}

export type TowBuildOrder = {
  __typename?: 'TowBuildOrder'
  dropOffFacility: Maybe<Scalars['String']['output']>
  latestInfo: Maybe<Scalars['String']['output']>
}

export type TowConfiguration = {
  __typename?: 'TowConfiguration'
  boat: Scalars['BoatId']['output']
  goal: GoalId
  hasTurnboat: Scalars['Boolean']['output']
  numberOfBarges: Scalars['Int']['output']
  numberOfEmptyBarges: Scalars['Int']['output']
  numberOfStrings: Maybe<Scalars['Int']['output']>
  preselectedBarges: Array<Scalars['BargeId']['output']>
}

export type TowConfigurationInput = {
  boat: Scalars['BoatId']['input']
  goal: GoalId
  hasTurnboat: InputMaybe<Scalars['Boolean']['input']>
  numberOfBarges: Scalars['Int']['input']
  numberOfEmptyBarges: Scalars['Int']['input']
  numberOfStrings: InputMaybe<Scalars['Int']['input']>
  preselectedBarges: InputMaybe<Array<Scalars['BargeId']['input']>>
}

export type TowShape = {
  __typename?: 'TowShape'
  empty: Scalars['Int']['output']
  loaded: Scalars['Int']['output']
  strings: Maybe<Scalars['Int']['output']>
}

export type TripStatus = {
  __typename?: 'TripStatus'
  id: GraphqlTripStatus
  label: Scalars['String']['output']
}

export enum TripStatusId {
  ArrivedForDischarge = 'ArrivedForDischarge',
  ArrivedForLoad = 'ArrivedForLoad',
  Canceled = 'Canceled',
  CanceledDestocking = 'CanceledDestocking',
  CanceledEmpty = 'CanceledEmpty',
  CanceledTramp = 'CanceledTramp',
  Destocking = 'Destocking',
  EmptyMove = 'EmptyMove',
  EmptyTramp = 'EmptyTramp',
  FinishedDischarge = 'FinishedDischarge',
  FinishedLoad = 'FinishedLoad',
  FinishedPartialDischarge = 'FinishedPartialDischarge',
  FinishedPartialLoad = 'FinishedPartialLoad',
  IntermediateOffPlacement = 'IntermediateOffPlacement',
  IntermediateOnPlacement = 'IntermediateOnPlacement',
  LoadedTramp = 'LoadedTramp',
  OnEmptyStorage = 'OnEmptyStorage',
  OnStorage = 'OnStorage',
  Pending = 'Pending',
  PlacedDischarge = 'PlacedDischarge',
  PlacedLoad = 'PlacedLoad',
  PlacedPartialDischarge = 'PlacedPartialDischarge',
  PlacedPartialLoad = 'PlacedPartialLoad',
  Planned = 'Planned',
  PlannedEmpty = 'PlannedEmpty',
  TrampArrivedForDischarge = 'TrampArrivedForDischarge',
  TrampArrivedForLoad = 'TrampArrivedForLoad',
  TrampFinishedDischarge = 'TrampFinishedDischarge',
  TrampFinishedLoad = 'TrampFinishedLoad',
  TrampFinishedPartialDischarge = 'TrampFinishedPartialDischarge',
  TrampFinishedPartialLoad = 'TrampFinishedPartialLoad',
  TrampPlacedDischarge = 'TrampPlacedDischarge',
  TrampPlacedLoad = 'TrampPlacedLoad',
  TrampPlacedPartialDischarge = 'TrampPlacedPartialDischarge',
  TrampPlacedPartialLoad = 'TrampPlacedPartialLoad',
  Transfer = 'Transfer',
}

export type UserBargeNomination = {
  __typename?: 'UserBargeNomination'
  crossEfficiencyGoalScores: Array<Array<CrossEfficiencyGoalScore>>
  owner: Scalars['String']['output']
  recordTime: Scalars['ZonedDateTime']['output']
  tows: Array<NominatedTow>
  userId: Scalars['CognitoId']['output']
  userRequest: BargeNominationRequest
  uuid: Scalars['NominationId']['output']
}

export type VersionedNominationFilters = {
  __typename?: 'VersionedNominationFilters'
  excludeBargeTypes: Array<GraphqlBargeType>
  excludeNonNominatableBarges: Scalars['Boolean']['output']
  excludeShuttleMoves: Scalars['Boolean']['output']
  excludeTboInfoBarges: Scalars['Boolean']['output']
  excludeTripStatuses: Array<TripStatusId>
  expectedDepartureTime: Maybe<Scalars['ZonedDateTime']['output']>
  includeTBOs: Array<Scalars['Long']['output']>
  lane: LaneId
  maximumDraft: Maybe<Scalars['Float']['output']>
  towDestination: HubLikeId
  towOrigin: HubLikeId
}

export type VersionedNominationFiltersInput = {
  excludeBargeTypes: Array<GraphqlBargeType>
  excludeNonNominatableBarges: Scalars['Boolean']['input']
  excludeShuttleMoves: Scalars['Boolean']['input']
  excludeTboInfoBarges: Scalars['Boolean']['input']
  excludeTripStatuses: Array<TripStatusId>
  expectedDepartureTime: InputMaybe<Scalars['ZonedDateTime']['input']>
  includeTBOs: Array<Scalars['Long']['input']>
  lane: LaneId
  maximumDraft: InputMaybe<Scalars['Float']['input']>
  towDestination: HubLikeId
  towOrigin: HubLikeId
}

export type VersionedTowConfiguration = {
  __typename?: 'VersionedTowConfiguration'
  boat: Scalars['BoatId']['output']
  excludeBargeIds: Array<Scalars['BargeId']['output']>
  goal: GoalId
  hasTurnboat: Scalars['Boolean']['output']
  includeBargeIds: Array<Scalars['BargeId']['output']>
  numberOfBarges: Scalars['Int']['output']
  numberOfEmptyBarges: Scalars['Int']['output']
  numberOfStrings: Maybe<Scalars['Int']['output']>
  prioritizeHotBarges: Scalars['Boolean']['output']
}

export type VersionedTowConfigurationInput = {
  boat: Scalars['BoatId']['input']
  excludeBargeIds: InputMaybe<Array<Scalars['BargeId']['input']>>
  goal: GoalId
  hasTurnboat: InputMaybe<Scalars['Boolean']['input']>
  includeBargeIds: InputMaybe<Array<Scalars['BargeId']['input']>>
  numberOfBarges: Scalars['Int']['input']
  numberOfEmptyBarges: Scalars['Int']['input']
  numberOfStrings: InputMaybe<Scalars['Int']['input']>
  prioritizeHotBarges: InputMaybe<Scalars['Boolean']['input']>
}

export type ArchiveNominationVersionMutationVariables = Exact<{
  nominationId: Scalars['NominationId']['input']
  versionId: Scalars['NominationVersionId']['input']
}>

export type ArchiveNominationVersionMutation = {
  __typename?: 'Mutation'
  archiveNominationVersion: { __typename: 'ArchivingFailure'; message: string } | { __typename: 'ArchivingSuccess' }
}

export type ArchiveNominationMutationVariables = Exact<{
  nominationId: Scalars['NominationId']['input']
}>

export type ArchiveNominationMutation = {
  __typename?: 'Mutation'
  archiveNomination: { __typename: 'ArchivingFailure'; message: string } | { __typename: 'ArchivingSuccess' }
}

export type SavedBargeNominationsQueryVariables = Exact<{ [key: string]: never }>

export type SavedBargeNominationsQuery = {
  __typename?: 'Query'
  savedBargeNominations: Array<{
    __typename?: 'SavedBargeNomination'
    nomination: {
      __typename?: 'UserBargeNomination'
      uuid: any
      recordTime: any
      userRequest: {
        __typename?: 'BargeNominationRequest'
        prioritizeHotBarges: boolean
        bargeFilters: {
          __typename?: 'BargeNominationFilters'
          lane: LaneId
          towOrigin: HubLikeId
          towDestination: HubLikeId
          excludeBargeIds: Array<any>
          excludeBargeTypes: Array<GraphqlBargeType>
          excludeTripStatuses: Array<TripStatusId>
          excludeTboInfoBarges: boolean
          excludeShuttleMoves: boolean
          maximumDraft: number | null
          expectedDepartureTime: any | null
          includeTBOs: Array<any>
          excludeNonNominatableBarges: boolean
        }
        towConfiguration: {
          __typename?: 'TowConfiguration'
          goal: GoalId
          numberOfBarges: number
          preselectedBarges: Array<any>
          numberOfEmptyBarges: number
          boat: any
          hasTurnboat: boolean
          numberOfStrings: number | null
        }
      }
      tows: Array<{
        __typename?: 'NominatedTow'
        efficiencyMetric: number | null
        hasTurnboat: boolean
        boat: { __typename?: 'NominatableBoat'; name: string | null; boatId: any; abbreviation: string | null }
        tbnBarges: Array<{
          __typename?: 'NominatedTBNBarge'
          expectedLoadStatus: LoadStatus
          pickupFacility: {
            __typename?: 'FacilityLocation'
            name: string | null
            facilityCode: any | null
            code: any
            mileagePoint: number
          }
          dropOffFacility: {
            __typename?: 'FacilityLocation'
            name: string | null
            facilityCode: any | null
            code: any
            mileagePoint: number
          }
        }>
        barges: Array<{
          __typename?: 'NominatedBarge'
          id: any
          name: string | null
          cargo: string | null
          hullType: HullType | null
          pickupType: PickupType
          isPreselected: boolean
          isHot: boolean
          type: GraphqlBargeType
          distanceToPickup: number | null
          riskLevel: RiskLevel
          tripStatus: TripStatusId
          loadStatus: LoadStatus
          expectedLoadStatus: LoadStatus
          fleet: { __typename?: 'Fleet'; name: string | null } | null
          currentLocation: { __typename?: 'RiverLocationLite'; code: any; mileagePoint: number } | null
          destination: { __typename?: 'RiverLocationLite'; code: any; mileagePoint: number } | null
          pickupFacility: {
            __typename?: 'FacilityLocation'
            name: string | null
            facilityCode: any | null
            code: any
            mileagePoint: number
          }
          dropOffFacility: {
            __typename?: 'FacilityLocation'
            name: string | null
            facilityCode: any | null
            code: any
            mileagePoint: number
          }
          towBuildOrder: { __typename?: 'TowBuildOrder'; latestInfo: string | null } | null
        }>
        stopsWithMetrics: Array<{
          __typename?: 'StopWithMetrics'
          dwellMinutes: any | null
          distanceToNextStop: number | null
          travelMinutesToNextStop: any | null
          stop: { __typename?: 'RiverLocationLite'; code: any; mileagePoint: number }
        }>
      }>
    }
  }>
}

export type SavedBargeNominationQueryVariables = Exact<{
  uuid: Scalars['NominationId']['input']
}>

export type SavedBargeNominationQuery = {
  __typename?: 'Query'
  savedBargeNomination: {
    __typename?: 'SavedBargeNomination'
    tboSubmissionStatus: TboSubmissionStatusId
    review: {
      __typename?: 'NominationReview'
      leftTheBargePool: Array<any>
      receivedTBO: Array<{ __typename?: 'ReceivedTBO'; bargeId: any; tbo: string }>
      changedRiskLevel: Array<{ __typename?: 'ChangedRiskLevel'; bargeId: any; newRiskLevel: RiskLevel }>
    }
    nomination: {
      __typename?: 'UserBargeNomination'
      uuid: any
      recordTime: any
      userRequest: {
        __typename?: 'BargeNominationRequest'
        prioritizeHotBarges: boolean
        bargeFilters: {
          __typename?: 'BargeNominationFilters'
          lane: LaneId
          towOrigin: HubLikeId
          towDestination: HubLikeId
          excludeBargeIds: Array<any>
          excludeBargeTypes: Array<GraphqlBargeType>
          excludeTripStatuses: Array<TripStatusId>
          excludeTboInfoBarges: boolean
          excludeShuttleMoves: boolean
          maximumDraft: number | null
          expectedDepartureTime: any | null
          includeTBOs: Array<any>
          excludeNonNominatableBarges: boolean
        }
        towConfiguration: {
          __typename?: 'TowConfiguration'
          goal: GoalId
          numberOfBarges: number
          preselectedBarges: Array<any>
          numberOfEmptyBarges: number
          boat: any
          hasTurnboat: boolean
          numberOfStrings: number | null
        }
      }
      tows: Array<{
        __typename?: 'NominatedTow'
        efficiencyMetric: number | null
        hasTurnboat: boolean
        boat: { __typename?: 'NominatableBoat'; name: string | null; boatId: any; abbreviation: string | null }
        tbnBarges: Array<{
          __typename?: 'NominatedTBNBarge'
          expectedLoadStatus: LoadStatus
          pickupFacility: {
            __typename?: 'FacilityLocation'
            name: string | null
            facilityCode: any | null
            code: any
            mileagePoint: number
          }
          dropOffFacility: {
            __typename?: 'FacilityLocation'
            name: string | null
            facilityCode: any | null
            code: any
            mileagePoint: number
          }
        }>
        barges: Array<{
          __typename?: 'NominatedBarge'
          id: any
          name: string | null
          cargo: string | null
          hullType: HullType | null
          pickupType: PickupType
          isPreselected: boolean
          isHot: boolean
          type: GraphqlBargeType
          distanceToPickup: number | null
          riskLevel: RiskLevel
          tripStatus: TripStatusId
          loadStatus: LoadStatus
          expectedLoadStatus: LoadStatus
          fleet: { __typename?: 'Fleet'; name: string | null } | null
          currentLocation: { __typename?: 'RiverLocationLite'; code: any; mileagePoint: number } | null
          destination: { __typename?: 'RiverLocationLite'; code: any; mileagePoint: number } | null
          pickupFacility: {
            __typename?: 'FacilityLocation'
            name: string | null
            facilityCode: any | null
            code: any
            mileagePoint: number
          }
          dropOffFacility: {
            __typename?: 'FacilityLocation'
            name: string | null
            facilityCode: any | null
            code: any
            mileagePoint: number
          }
          towBuildOrder: { __typename?: 'TowBuildOrder'; latestInfo: string | null } | null
        }>
        stopsWithMetrics: Array<{
          __typename?: 'StopWithMetrics'
          dwellMinutes: any | null
          distanceToNextStop: number | null
          travelMinutesToNextStop: any | null
          stop: { __typename?: 'RiverLocationLite'; code: any; mileagePoint: number }
        }>
      }>
    }
  } | null
}

export type CreateBargeNominationMutationVariables = Exact<{
  bargeNomination: BargeNominationInput
}>

export type CreateBargeNominationMutation = {
  __typename?: 'Mutation'
  createBargeNomination:
    | { __typename: 'NominationCreateFailure'; message: string; errors: Array<string> }
    | {
        __typename: 'NominationCreateSuccess'
        nomination: {
          __typename?: 'UserBargeNomination'
          uuid: any
          recordTime: any
          userRequest: {
            __typename?: 'BargeNominationRequest'
            prioritizeHotBarges: boolean
            bargeFilters: {
              __typename?: 'BargeNominationFilters'
              lane: LaneId
              towOrigin: HubLikeId
              towDestination: HubLikeId
              excludeBargeIds: Array<any>
              excludeBargeTypes: Array<GraphqlBargeType>
              excludeTripStatuses: Array<TripStatusId>
              excludeTboInfoBarges: boolean
              excludeShuttleMoves: boolean
              maximumDraft: number | null
              expectedDepartureTime: any | null
              includeTBOs: Array<any>
              excludeNonNominatableBarges: boolean
            }
            towConfiguration: {
              __typename?: 'TowConfiguration'
              goal: GoalId
              numberOfBarges: number
              preselectedBarges: Array<any>
              numberOfEmptyBarges: number
              boat: any
              hasTurnboat: boolean
              numberOfStrings: number | null
            }
          }
          tows: Array<{
            __typename?: 'NominatedTow'
            efficiencyMetric: number | null
            hasTurnboat: boolean
            boat: { __typename?: 'NominatableBoat'; name: string | null; boatId: any; abbreviation: string | null }
            tbnBarges: Array<{
              __typename?: 'NominatedTBNBarge'
              expectedLoadStatus: LoadStatus
              pickupFacility: {
                __typename?: 'FacilityLocation'
                name: string | null
                facilityCode: any | null
                code: any
                mileagePoint: number
              }
              dropOffFacility: {
                __typename?: 'FacilityLocation'
                name: string | null
                facilityCode: any | null
                code: any
                mileagePoint: number
              }
            }>
            barges: Array<{
              __typename?: 'NominatedBarge'
              id: any
              name: string | null
              cargo: string | null
              hullType: HullType | null
              pickupType: PickupType
              isPreselected: boolean
              isHot: boolean
              type: GraphqlBargeType
              distanceToPickup: number | null
              riskLevel: RiskLevel
              tripStatus: TripStatusId
              loadStatus: LoadStatus
              expectedLoadStatus: LoadStatus
              fleet: { __typename?: 'Fleet'; name: string | null } | null
              currentLocation: { __typename?: 'RiverLocationLite'; code: any; mileagePoint: number } | null
              destination: { __typename?: 'RiverLocationLite'; code: any; mileagePoint: number } | null
              pickupFacility: {
                __typename?: 'FacilityLocation'
                name: string | null
                facilityCode: any | null
                code: any
                mileagePoint: number
              }
              dropOffFacility: {
                __typename?: 'FacilityLocation'
                name: string | null
                facilityCode: any | null
                code: any
                mileagePoint: number
              }
              towBuildOrder: { __typename?: 'TowBuildOrder'; latestInfo: string | null } | null
            }>
            stopsWithMetrics: Array<{
              __typename?: 'StopWithMetrics'
              dwellMinutes: any | null
              distanceToNextStop: number | null
              travelMinutesToNextStop: any | null
              stop: { __typename?: 'RiverLocationLite'; code: any; mileagePoint: number }
            }>
          }>
        }
      }
}

export type ReplaceNominationBargesMutationVariables = Exact<{
  nominationId: Scalars['NominationId']['input']
}>

export type ReplaceNominationBargesMutation = {
  __typename?: 'Mutation'
  replaceNominationBarges:
    | { __typename: 'NominationReplaceBargesFailure'; message: string; errors: Array<string> }
    | {
        __typename: 'NominationReplaceBargesSuccess'
        nomination: {
          __typename?: 'UserBargeNomination'
          uuid: any
          recordTime: any
          userRequest: {
            __typename?: 'BargeNominationRequest'
            prioritizeHotBarges: boolean
            bargeFilters: {
              __typename?: 'BargeNominationFilters'
              lane: LaneId
              towOrigin: HubLikeId
              towDestination: HubLikeId
              excludeBargeIds: Array<any>
              excludeBargeTypes: Array<GraphqlBargeType>
              excludeTripStatuses: Array<TripStatusId>
              excludeTboInfoBarges: boolean
              excludeShuttleMoves: boolean
              maximumDraft: number | null
              expectedDepartureTime: any | null
              includeTBOs: Array<any>
              excludeNonNominatableBarges: boolean
            }
            towConfiguration: {
              __typename?: 'TowConfiguration'
              goal: GoalId
              numberOfBarges: number
              preselectedBarges: Array<any>
              numberOfEmptyBarges: number
              boat: any
              hasTurnboat: boolean
              numberOfStrings: number | null
            }
          }
          tows: Array<{
            __typename?: 'NominatedTow'
            efficiencyMetric: number | null
            hasTurnboat: boolean
            boat: { __typename?: 'NominatableBoat'; name: string | null; boatId: any; abbreviation: string | null }
            tbnBarges: Array<{
              __typename?: 'NominatedTBNBarge'
              expectedLoadStatus: LoadStatus
              pickupFacility: {
                __typename?: 'FacilityLocation'
                name: string | null
                facilityCode: any | null
                code: any
                mileagePoint: number
              }
              dropOffFacility: {
                __typename?: 'FacilityLocation'
                name: string | null
                facilityCode: any | null
                code: any
                mileagePoint: number
              }
            }>
            barges: Array<{
              __typename?: 'NominatedBarge'
              id: any
              name: string | null
              cargo: string | null
              hullType: HullType | null
              pickupType: PickupType
              isPreselected: boolean
              isHot: boolean
              type: GraphqlBargeType
              distanceToPickup: number | null
              riskLevel: RiskLevel
              tripStatus: TripStatusId
              loadStatus: LoadStatus
              expectedLoadStatus: LoadStatus
              fleet: { __typename?: 'Fleet'; name: string | null } | null
              currentLocation: { __typename?: 'RiverLocationLite'; code: any; mileagePoint: number } | null
              destination: { __typename?: 'RiverLocationLite'; code: any; mileagePoint: number } | null
              pickupFacility: {
                __typename?: 'FacilityLocation'
                name: string | null
                facilityCode: any | null
                code: any
                mileagePoint: number
              }
              dropOffFacility: {
                __typename?: 'FacilityLocation'
                name: string | null
                facilityCode: any | null
                code: any
                mileagePoint: number
              }
              towBuildOrder: { __typename?: 'TowBuildOrder'; latestInfo: string | null } | null
            }>
            stopsWithMetrics: Array<{
              __typename?: 'StopWithMetrics'
              dwellMinutes: any | null
              distanceToNextStop: number | null
              travelMinutesToNextStop: any | null
              stop: { __typename?: 'RiverLocationLite'; code: any; mileagePoint: number }
            }>
          }>
        }
      }
}

export type SaveBargeNominationMutationVariables = Exact<{
  uuid: Scalars['NominationId']['input']
}>

export type SaveBargeNominationMutation = {
  __typename?: 'Mutation'
  saveBargeNomination: { __typename?: 'NominationSaveSuccess'; nominationId: any }
}

export type UpdateBargeNominationMutationVariables = Exact<{
  savedNominationId: Scalars['NominationId']['input']
  autoSavedNominationId: Scalars['NominationId']['input']
}>

export type UpdateBargeNominationMutation = {
  __typename?: 'Mutation'
  updateBargeNomination: { __typename?: 'NominationUpdateSuccess'; nominationId: any }
}

export type DeleteBargeNominationMutationVariables = Exact<{
  uuid: Scalars['NominationId']['input']
}>

export type DeleteBargeNominationMutation = {
  __typename?: 'Mutation'
  deleteBargeNomination: { __typename?: 'NominationDeleteSuccess'; nominationId: any }
}

export type SubmitBargeNominationMutationVariables = Exact<{
  uuid: Scalars['NominationId']['input']
}>

export type SubmitBargeNominationMutation = {
  __typename?: 'Mutation'
  submitBargeNomination: {
    __typename: 'NominationSubmitSuccess'
    nominationId: any
    tboSubmissionStatus: TboSubmissionStatusId
  }
}

export type BargesLastUpdatedQueryVariables = Exact<{ [key: string]: never }>

export type BargesLastUpdatedQuery = {
  __typename?: 'Query'
  lanes: Array<{ __typename?: 'Lane'; barges: Array<{ __typename?: 'BargeInPool'; lastUpdated: any }> }>
}

export type BoatsByDestinationQueryVariables = Exact<{
  destination: HubLikeId
}>

export type BoatsByDestinationQuery = {
  __typename?: 'Query'
  boats: Array<{ __typename?: 'NominatableBoat'; abbreviation: string | null; boatId: any; name: string | null }>
}

export type CreateNominationVersionMutationVariables = Exact<{
  request: GraphqlVersionRequest
}>

export type CreateNominationVersionMutation = {
  __typename?: 'Mutation'
  createVersion:
    | { __typename: 'CreationFailure'; message: string; errors: Array<string> }
    | { __typename: 'CreationSuccess'; nominationId: any; nominationVersionId: any }
}

export type CreateVersionedNominationMutationVariables = Exact<{
  request: GraphqlNewNominationRequest
}>

export type CreateVersionedNominationMutation = {
  __typename?: 'Mutation'
  createVersionedNomination:
    | { __typename: 'CreationFailure'; message: string; errors: Array<string> }
    | { __typename: 'CreationSuccess'; nominationId: any; nominationVersionId: any }
}

export type NominatedBargeFragmentFragment = {
  __typename?: 'NominatedBarge'
  id: any
  name: string | null
  cargo: string | null
  hullType: HullType | null
  pickupType: PickupType
  isPreselected: boolean
  isHot: boolean
  type: GraphqlBargeType
  distanceToPickup: number | null
  riskLevel: RiskLevel
  tripStatus: TripStatusId
  loadStatus: LoadStatus
  expectedLoadStatus: LoadStatus
  fleet: { __typename?: 'Fleet'; name: string | null } | null
  currentLocation: { __typename?: 'RiverLocationLite'; code: any; mileagePoint: number } | null
  destination: { __typename?: 'RiverLocationLite'; code: any; mileagePoint: number } | null
  pickupFacility: {
    __typename?: 'FacilityLocation'
    name: string | null
    facilityCode: any | null
    code: any
    mileagePoint: number
  }
  dropOffFacility: {
    __typename?: 'FacilityLocation'
    name: string | null
    facilityCode: any | null
    code: any
    mileagePoint: number
  }
  towBuildOrder: { __typename?: 'TowBuildOrder'; latestInfo: string | null } | null
}

export type TowConfigurationFragmentFragment = {
  __typename?: 'TowConfiguration'
  goal: GoalId
  numberOfBarges: number
  preselectedBarges: Array<any>
  numberOfEmptyBarges: number
  boat: any
  hasTurnboat: boolean
  numberOfStrings: number | null
}

export type BargeNominationFiltersFragmentFragment = {
  __typename?: 'BargeNominationFilters'
  lane: LaneId
  towOrigin: HubLikeId
  towDestination: HubLikeId
  excludeBargeIds: Array<any>
  excludeBargeTypes: Array<GraphqlBargeType>
  excludeTripStatuses: Array<TripStatusId>
  excludeTboInfoBarges: boolean
  excludeShuttleMoves: boolean
  maximumDraft: number | null
  expectedDepartureTime: any | null
  includeTBOs: Array<any>
  excludeNonNominatableBarges: boolean
}

export type NominatedTowFragmentFragment = {
  __typename?: 'NominatedTow'
  efficiencyMetric: number | null
  hasTurnboat: boolean
  boat: { __typename?: 'NominatableBoat'; name: string | null; boatId: any; abbreviation: string | null }
  tbnBarges: Array<{
    __typename?: 'NominatedTBNBarge'
    expectedLoadStatus: LoadStatus
    pickupFacility: {
      __typename?: 'FacilityLocation'
      name: string | null
      facilityCode: any | null
      code: any
      mileagePoint: number
    }
    dropOffFacility: {
      __typename?: 'FacilityLocation'
      name: string | null
      facilityCode: any | null
      code: any
      mileagePoint: number
    }
  }>
  barges: Array<{
    __typename?: 'NominatedBarge'
    id: any
    name: string | null
    cargo: string | null
    hullType: HullType | null
    pickupType: PickupType
    isPreselected: boolean
    isHot: boolean
    type: GraphqlBargeType
    distanceToPickup: number | null
    riskLevel: RiskLevel
    tripStatus: TripStatusId
    loadStatus: LoadStatus
    expectedLoadStatus: LoadStatus
    fleet: { __typename?: 'Fleet'; name: string | null } | null
    currentLocation: { __typename?: 'RiverLocationLite'; code: any; mileagePoint: number } | null
    destination: { __typename?: 'RiverLocationLite'; code: any; mileagePoint: number } | null
    pickupFacility: {
      __typename?: 'FacilityLocation'
      name: string | null
      facilityCode: any | null
      code: any
      mileagePoint: number
    }
    dropOffFacility: {
      __typename?: 'FacilityLocation'
      name: string | null
      facilityCode: any | null
      code: any
      mileagePoint: number
    }
    towBuildOrder: { __typename?: 'TowBuildOrder'; latestInfo: string | null } | null
  }>
  stopsWithMetrics: Array<{
    __typename?: 'StopWithMetrics'
    dwellMinutes: any | null
    distanceToNextStop: number | null
    travelMinutesToNextStop: any | null
    stop: { __typename?: 'RiverLocationLite'; code: any; mileagePoint: number }
  }>
}

export type LaneBargesQueryVariables = Exact<{
  laneId: InputMaybe<LaneId>
  originId: InputMaybe<HubLikeId>
  destinationId: InputMaybe<HubLikeId>
  excludeBargeTypes: InputMaybe<Array<GraphqlBargeType> | GraphqlBargeType>
  excludeTripStatuses: InputMaybe<Array<GraphqlTripStatus> | GraphqlTripStatus>
  excludeTboInfoBarges: InputMaybe<Scalars['Boolean']['input']>
  excludeShuttleMoves: InputMaybe<Scalars['Boolean']['input']>
  maxDraft: InputMaybe<Scalars['Float']['input']>
  time: InputMaybe<Scalars['ZonedDateTime']['input']>
  includeTBOs: InputMaybe<Array<Scalars['Long']['input']> | Scalars['Long']['input']>
  includeBargeIds: InputMaybe<Array<Scalars['BargeId']['input']> | Scalars['BargeId']['input']>
}>

export type LaneBargesQuery = {
  __typename?: 'Query'
  lanes: Array<{
    __typename?: 'Lane'
    barges: Array<{
      __typename?: 'BargeInPool'
      isHot: boolean
      riskLevel: RiskLevel | null
      status: CustodyStatus
      expectedTripLoadStatus: LoadStatus
      timeInFleet: any | null
      pickupType: PickupType | null
      pickupFacility: {
        __typename?: 'FacilityLocation'
        name: string | null
        facilityCode: any | null
        code: any
        mileagePoint: number
      } | null
      dropOffFacility: {
        __typename?: 'FacilityLocation'
        name: string | null
        facilityCode: any | null
        code: any
        mileagePoint: number
      } | null
      group: { __typename?: 'BargeGroup'; uuid: any; barges: Array<any> } | null
      barge: {
        __typename?: 'OverviewBarge'
        id: any
        name: string | null
        type: GraphqlBargeType
        hullType: HullType | null
      }
      latestTBO: { __typename?: 'OverviewBargeTBO'; dropFacility: string | null; info: string | null } | null
      currentTrip: {
        __typename?: 'OverviewTrip'
        startTime: any | null
        tripStatusTime: any | null
        loadStatus: LoadStatus
        status: TripStatusId
        cargo: string | null
        actualDraft: number | null
        origin: {
          __typename?: 'FacilityLocation'
          name: string | null
          facilityCode: any | null
          code: any
          mileagePoint: number
        } | null
        destination: {
          __typename?: 'FacilityLocation'
          name: string | null
          facilityCode: any | null
          code: any
          mileagePoint: number
        } | null
      }
      currentLocation: {
        __typename?: 'OverviewLocation'
        locationCode: string | null
        riverLocation: { __typename?: 'RiverLocationLite'; code: any; mileagePoint: number }
      }
      custodyInfo: {
        __typename?: 'OverviewCustodyInfo'
        custody: string
        boat: {
          __typename?: 'OverviewCustodyBoat'
          name: string | null
          eta: any | null
          activeOrder: { __typename?: 'OrderRevisionId'; number: any; revisionNumber: number } | null
          destination: { __typename?: 'FacilityLocation'; name: string | null; code: any; mileagePoint: number } | null
        } | null
        facility: { __typename?: 'CustodyFacility'; name: string } | null
        fleet: { __typename?: 'Fleet'; name: string | null } | null
      }
      nextDropOffDetails: {
        __typename?: 'OverviewDropOffDetails'
        estimatedDropOffTime: any | null
        willBeDroppedAtNextStop: boolean
        facility: { __typename?: 'FacilityLocation'; name: string | null; code: any; mileagePoint: number }
      } | null
    }>
  }>
}

export type NominationByIdQueryVariables = Exact<{
  id: Scalars['NominationId']['input']
}>

export type NominationByIdQuery = {
  __typename?: 'Query'
  nominationById: {
    __typename?: 'OverviewNominationWithVersions'
    nomination: {
      __typename?: 'OverviewNomination'
      id: any
      slug: any
      recordTime: any
      tboLinkStatus: TboLinkStatus
      tboNumber: any | null
    }
    versions: Array<{
      __typename?: 'OverviewNominationVersion'
      id: any
      slug: any
      type: OverviewNominationVersionType
      recordTime: any
      tboSubmissionStatus: TboSubmissionStatusId | null
      nominationRequest: {
        __typename?: 'OverviewVersionedNominationRequest'
        filters: {
          __typename?: 'VersionedNominationFilters'
          lane: LaneId
          towOrigin: HubLikeId
          towDestination: HubLikeId
          excludeBargeTypes: Array<GraphqlBargeType>
          excludeTripStatuses: Array<TripStatusId>
          excludeTboInfoBarges: boolean
          excludeShuttleMoves: boolean
          maximumDraft: number | null
          expectedDepartureTime: any | null
          includeTBOs: Array<any>
          excludeNonNominatableBarges: boolean
        }
        configuration: {
          __typename?: 'VersionedTowConfiguration'
          goal: GoalId
          numberOfBarges: number
          includeBargeIds: Array<any>
          excludeBargeIds: Array<any>
          numberOfEmptyBarges: number
          boat: any
          hasTurnboat: boolean
          numberOfStrings: number | null
          prioritizeHotBarges: boolean
        }
      } | null
      tow: {
        __typename?: 'NominatedTow'
        efficiencyMetric: number | null
        hasTurnboat: boolean
        boat: { __typename?: 'NominatableBoat'; name: string | null; boatId: any; abbreviation: string | null }
        barges: Array<{
          __typename?: 'NominatedBarge'
          id: any
          name: string | null
          cargo: string | null
          hullType: HullType | null
          pickupType: PickupType
          isPreselected: boolean
          isHot: boolean
          type: GraphqlBargeType
          distanceToPickup: number | null
          riskLevel: RiskLevel
          tripStatus: TripStatusId
          loadStatus: LoadStatus
          expectedLoadStatus: LoadStatus
          fleet: { __typename?: 'Fleet'; name: string | null } | null
          currentLocation: { __typename?: 'RiverLocationLite'; code: any; mileagePoint: number } | null
          destination: { __typename?: 'RiverLocationLite'; code: any; mileagePoint: number } | null
          pickupFacility: {
            __typename?: 'FacilityLocation'
            name: string | null
            facilityCode: any | null
            code: any
            mileagePoint: number
          }
          dropOffFacility: {
            __typename?: 'FacilityLocation'
            name: string | null
            facilityCode: any | null
            code: any
            mileagePoint: number
          }
          towBuildOrder: { __typename?: 'TowBuildOrder'; latestInfo: string | null } | null
        }>
        tbnBarges: Array<{
          __typename?: 'NominatedTBNBarge'
          expectedLoadStatus: LoadStatus
          pickupFacility: {
            __typename?: 'FacilityLocation'
            name: string | null
            facilityCode: any | null
            code: any
            mileagePoint: number
          }
          dropOffFacility: {
            __typename?: 'FacilityLocation'
            name: string | null
            facilityCode: any | null
            code: any
            mileagePoint: number
          }
        }>
        stopsWithMetrics: Array<{
          __typename?: 'StopWithMetrics'
          dwellMinutes: any | null
          distanceToNextStop: number | null
          travelMinutesToNextStop: any | null
          stop: { __typename?: 'RiverLocationLite'; code: any; mileagePoint: number }
        }>
      }
      orderRevisionId: { __typename?: 'OrderRevisionId'; number: any; headerId: any; revisionNumber: number } | null
    }>
  } | null
}

export type NominationsQueryVariables = Exact<{
  ownedBy: InputMaybe<OwnedBy>
}>

export type NominationsQuery = {
  __typename?: 'Query'
  nominations: Array<{
    __typename?: 'LatestNominationVersionSummary'
    id: any
    lane: LaneId | null
    expectedDepartureTime: any | null
    tboNumber: any | null
    tboLinkStatus: TboLinkStatus
    recordTime: any
    origin: {
      __typename?: 'FacilityLocation'
      code: any
      name: string | null
      mileagePoint: number
      facilityCode: any | null
    } | null
    destination: {
      __typename?: 'FacilityLocation'
      code: any
      name: string | null
      mileagePoint: number
      facilityCode: any | null
    } | null
    owner: { __typename?: 'Owner'; cognitoId: any; username: string } | null
    towShape: { __typename?: 'TowShape'; empty: number; loaded: number; strings: number | null } | null
  }>
}

export type SettingsQueryVariables = Exact<{ [key: string]: never }>

export type SettingsQuery = {
  __typename?: 'Query'
  lanes: Array<{ __typename?: 'Lane'; id: LaneId; label: string }>
  hubLikes: Array<
    | {
        __typename: 'Hub'
        id: HubLikeId
        label: string
        riverLocation: { __typename?: 'RiverLocationLite'; code: any; mileagePoint: number }
      }
    | {
        __typename: 'NonHub'
        id: HubLikeId
        label: string
        riverLocation: { __typename?: 'RiverLocationLite'; code: any; mileagePoint: number }
      }
  >
  goals: Array<{ __typename?: 'Goal'; id: GoalId; label: string; description: string }>
  tripStatuses: Array<{ __typename?: 'TripStatus'; id: GraphqlTripStatus; label: string }>
  boats: Array<{ __typename?: 'NominatableBoat'; boatId: any; abbreviation: string | null; name: string | null }>
  tboSubmissionStatuses: Array<{ __typename?: 'TBOSubmissionStatus'; id: TboSubmissionStatusId; displayName: string }>
}

export type SubmitNominationVersionMutationVariables = Exact<{
  nominationId: Scalars['NominationId']['input']
  versionId: Scalars['NominationVersionId']['input']
}>

export type SubmitNominationVersionMutation = {
  __typename?: 'Mutation'
  submitVersionToTbo: { __typename: 'SubmitFailure'; message: string } | { __typename: 'SubmitSuccess' }
}

export const TowConfigurationFragmentFragmentDoc = gql`
  fragment TowConfigurationFragment on TowConfiguration {
    goal
    numberOfBarges
    preselectedBarges
    numberOfEmptyBarges
    boat
    hasTurnboat
    numberOfStrings
  }
`
export const BargeNominationFiltersFragmentFragmentDoc = gql`
  fragment BargeNominationFiltersFragment on BargeNominationFilters {
    lane
    towOrigin
    towDestination
    excludeBargeIds
    excludeBargeTypes
    excludeTripStatuses
    excludeTboInfoBarges
    excludeShuttleMoves
    maximumDraft
    expectedDepartureTime
    includeTBOs
    excludeNonNominatableBarges
  }
`
export const NominatedBargeFragmentFragmentDoc = gql`
  fragment NominatedBargeFragment on NominatedBarge {
    id
    name
    cargo
    hullType
    pickupType
    isPreselected
    isHot
    type
    fleet {
      name
    }
    distanceToPickup
    riskLevel
    tripStatus
    loadStatus
    expectedLoadStatus
    currentLocation {
      code
      mileagePoint
    }
    destination {
      code
      mileagePoint
    }
    pickupFacility {
      name
      facilityCode
      code
      mileagePoint
    }
    dropOffFacility {
      name
      facilityCode
      code
      mileagePoint
    }
    towBuildOrder {
      latestInfo
    }
  }
`
export const NominatedTowFragmentFragmentDoc = gql`
  fragment NominatedTowFragment on NominatedTow {
    efficiencyMetric
    boat {
      name
      boatId
      abbreviation
    }
    tbnBarges {
      pickupFacility {
        name
        facilityCode
        code
        mileagePoint
      }
      dropOffFacility {
        name
        facilityCode
        code
        mileagePoint
      }
      expectedLoadStatus
    }
    barges {
      ...NominatedBargeFragment
    }
    hasTurnboat
    stopsWithMetrics {
      dwellMinutes
      distanceToNextStop
      travelMinutesToNextStop
      stop {
        code
        mileagePoint
      }
    }
  }
  ${NominatedBargeFragmentFragmentDoc}
`
export const ArchiveNominationVersionDocument = gql`
  mutation archiveNominationVersion($nominationId: NominationId!, $versionId: NominationVersionId!) {
    archiveNominationVersion(nominationId: $nominationId, nominationVersionId: $versionId) {
      __typename
      ... on ArchivingFailure {
        message
      }
    }
  }
`

export function useArchiveNominationVersionMutation() {
  return Urql.useMutation<ArchiveNominationVersionMutation, ArchiveNominationVersionMutationVariables>(
    ArchiveNominationVersionDocument
  )
}
export const ArchiveNominationDocument = gql`
  mutation archiveNomination($nominationId: NominationId!) {
    archiveNomination(nominationId: $nominationId) {
      __typename
      ... on ArchivingFailure {
        message
      }
    }
  }
`

export function useArchiveNominationMutation() {
  return Urql.useMutation<ArchiveNominationMutation, ArchiveNominationMutationVariables>(ArchiveNominationDocument)
}
export const SavedBargeNominationsDocument = gql`
  query savedBargeNominations {
    savedBargeNominations {
      nomination {
        uuid
        recordTime
        userRequest {
          bargeFilters {
            ...BargeNominationFiltersFragment
          }
          prioritizeHotBarges
          towConfiguration {
            ...TowConfigurationFragment
          }
        }
        tows {
          ...NominatedTowFragment
        }
      }
    }
  }
  ${BargeNominationFiltersFragmentFragmentDoc}
  ${TowConfigurationFragmentFragmentDoc}
  ${NominatedTowFragmentFragmentDoc}
`

export function useSavedBargeNominationsQuery(
  options?: Omit<Urql.UseQueryArgs<SavedBargeNominationsQueryVariables>, 'query'>
) {
  return Urql.useQuery<SavedBargeNominationsQuery, SavedBargeNominationsQueryVariables>({
    query: SavedBargeNominationsDocument,
    ...options,
  })
}
export const SavedBargeNominationDocument = gql`
  query savedBargeNomination($uuid: NominationId!) {
    savedBargeNomination(nominationId: $uuid) {
      review {
        receivedTBO {
          bargeId
          tbo
        }
        leftTheBargePool
        changedRiskLevel {
          bargeId
          newRiskLevel
        }
      }
      nomination {
        uuid
        recordTime
        userRequest {
          bargeFilters {
            ...BargeNominationFiltersFragment
          }
          prioritizeHotBarges
          towConfiguration {
            ...TowConfigurationFragment
          }
        }
        tows {
          ...NominatedTowFragment
        }
      }
      tboSubmissionStatus
    }
  }
  ${BargeNominationFiltersFragmentFragmentDoc}
  ${TowConfigurationFragmentFragmentDoc}
  ${NominatedTowFragmentFragmentDoc}
`

export function useSavedBargeNominationQuery(
  options: Omit<Urql.UseQueryArgs<SavedBargeNominationQueryVariables>, 'query'>
) {
  return Urql.useQuery<SavedBargeNominationQuery, SavedBargeNominationQueryVariables>({
    query: SavedBargeNominationDocument,
    ...options,
  })
}
export const CreateBargeNominationDocument = gql`
  mutation createBargeNomination($bargeNomination: BargeNominationInput!) {
    createBargeNomination(bargeNominationInput: $bargeNomination) {
      __typename
      ... on NominationCreateFailure {
        message
        errors
      }
      ... on NominationCreateSuccess {
        nomination {
          uuid
          recordTime
          userRequest {
            bargeFilters {
              ...BargeNominationFiltersFragment
            }
            prioritizeHotBarges
            towConfiguration {
              ...TowConfigurationFragment
            }
          }
          tows {
            ...NominatedTowFragment
          }
        }
      }
    }
  }
  ${BargeNominationFiltersFragmentFragmentDoc}
  ${TowConfigurationFragmentFragmentDoc}
  ${NominatedTowFragmentFragmentDoc}
`

export function useCreateBargeNominationMutation() {
  return Urql.useMutation<CreateBargeNominationMutation, CreateBargeNominationMutationVariables>(
    CreateBargeNominationDocument
  )
}
export const ReplaceNominationBargesDocument = gql`
  mutation replaceNominationBarges($nominationId: NominationId!) {
    replaceNominationBarges(nominationId: $nominationId) {
      __typename
      ... on NominationReplaceBargesSuccess {
        nomination {
          uuid
          recordTime
          userRequest {
            bargeFilters {
              ...BargeNominationFiltersFragment
            }
            prioritizeHotBarges
            towConfiguration {
              ...TowConfigurationFragment
            }
          }
          tows {
            ...NominatedTowFragment
          }
        }
      }
      ... on NominationReplaceBargesFailure {
        message
        errors
      }
    }
  }
  ${BargeNominationFiltersFragmentFragmentDoc}
  ${TowConfigurationFragmentFragmentDoc}
  ${NominatedTowFragmentFragmentDoc}
`

export function useReplaceNominationBargesMutation() {
  return Urql.useMutation<ReplaceNominationBargesMutation, ReplaceNominationBargesMutationVariables>(
    ReplaceNominationBargesDocument
  )
}
export const SaveBargeNominationDocument = gql`
  mutation saveBargeNomination($uuid: NominationId!) {
    saveBargeNomination(nominationId: $uuid) {
      nominationId
    }
  }
`

export function useSaveBargeNominationMutation() {
  return Urql.useMutation<SaveBargeNominationMutation, SaveBargeNominationMutationVariables>(
    SaveBargeNominationDocument
  )
}
export const UpdateBargeNominationDocument = gql`
  mutation updateBargeNomination($savedNominationId: NominationId!, $autoSavedNominationId: NominationId!) {
    updateBargeNomination(nominationId: $savedNominationId, latestBargeNominationId: $autoSavedNominationId) {
      nominationId
    }
  }
`

export function useUpdateBargeNominationMutation() {
  return Urql.useMutation<UpdateBargeNominationMutation, UpdateBargeNominationMutationVariables>(
    UpdateBargeNominationDocument
  )
}
export const DeleteBargeNominationDocument = gql`
  mutation deleteBargeNomination($uuid: NominationId!) {
    deleteBargeNomination(nominationId: $uuid) {
      nominationId
    }
  }
`

export function useDeleteBargeNominationMutation() {
  return Urql.useMutation<DeleteBargeNominationMutation, DeleteBargeNominationMutationVariables>(
    DeleteBargeNominationDocument
  )
}
export const SubmitBargeNominationDocument = gql`
  mutation submitBargeNomination($uuid: NominationId!) {
    submitBargeNomination(nominationId: $uuid) {
      __typename
      ... on NominationSubmitSuccess {
        nominationId
        tboSubmissionStatus
      }
    }
  }
`

export function useSubmitBargeNominationMutation() {
  return Urql.useMutation<SubmitBargeNominationMutation, SubmitBargeNominationMutationVariables>(
    SubmitBargeNominationDocument
  )
}
export const BargesLastUpdatedDocument = gql`
  query bargesLastUpdated {
    lanes {
      barges(bargesFilter: {}) {
        lastUpdated
      }
    }
  }
`

export function useBargesLastUpdatedQuery(options?: Omit<Urql.UseQueryArgs<BargesLastUpdatedQueryVariables>, 'query'>) {
  return Urql.useQuery<BargesLastUpdatedQuery, BargesLastUpdatedQueryVariables>({
    query: BargesLastUpdatedDocument,
    ...options,
  })
}
export const BoatsByDestinationDocument = gql`
  query boatsByDestination($destination: HubLikeId!) {
    boats(boatDestination: $destination) {
      abbreviation
      boatId
      name
    }
  }
`

export function useBoatsByDestinationQuery(
  options: Omit<Urql.UseQueryArgs<BoatsByDestinationQueryVariables>, 'query'>
) {
  return Urql.useQuery<BoatsByDestinationQuery, BoatsByDestinationQueryVariables>({
    query: BoatsByDestinationDocument,
    ...options,
  })
}
export const CreateNominationVersionDocument = gql`
  mutation createNominationVersion($request: GraphqlVersionRequest!) {
    createVersion(versionRequest: $request) {
      __typename
      ... on CreationFailure {
        message
        errors
      }
      ... on CreationSuccess {
        nominationId
        nominationVersionId
      }
    }
  }
`

export function useCreateNominationVersionMutation() {
  return Urql.useMutation<CreateNominationVersionMutation, CreateNominationVersionMutationVariables>(
    CreateNominationVersionDocument
  )
}
export const CreateVersionedNominationDocument = gql`
  mutation createVersionedNomination($request: GraphqlNewNominationRequest!) {
    createVersionedNomination(newNominationRequest: $request) {
      __typename
      ... on CreationFailure {
        message
        errors
      }
      ... on CreationSuccess {
        nominationId
        nominationVersionId
      }
    }
  }
`

export function useCreateVersionedNominationMutation() {
  return Urql.useMutation<CreateVersionedNominationMutation, CreateVersionedNominationMutationVariables>(
    CreateVersionedNominationDocument
  )
}
export const LaneBargesDocument = gql`
  query laneBarges(
    $laneId: LaneId
    $originId: HubLikeId
    $destinationId: HubLikeId
    $excludeBargeTypes: [GraphqlBargeType!]
    $excludeTripStatuses: [GraphqlTripStatus!]
    $excludeTboInfoBarges: Boolean
    $excludeShuttleMoves: Boolean
    $maxDraft: Float
    $time: ZonedDateTime
    $includeTBOs: [Long!]
    $includeBargeIds: [BargeId!]
  ) {
    lanes(laneId: $laneId) {
      barges(
        bargesFilter: {
          origin: $originId
          destination: $destinationId
          bargeTypesToExclude: $excludeBargeTypes
          tripStatusesToExclude: $excludeTripStatuses
          excludeTboInfoBarges: $excludeTboInfoBarges
          excludeShuttleMoves: $excludeShuttleMoves
          maximumDraft: $maxDraft
          expectedDepartureTime: $time
          includeTBOs: $includeTBOs
          includeBargeIds: $includeBargeIds
        }
      ) {
        isHot
        riskLevel
        status
        expectedTripLoadStatus
        timeInFleet
        pickupFacility {
          name
          facilityCode
          code
          mileagePoint
        }
        dropOffFacility {
          name
          facilityCode
          code
          mileagePoint
        }
        group {
          uuid
          barges
        }
        barge {
          id
          name
          type
          hullType
        }
        latestTBO {
          dropFacility
          info
        }
        currentTrip {
          origin {
            name
            facilityCode
            code
            mileagePoint
          }
          destination {
            name
            facilityCode
            code
            mileagePoint
          }
          startTime
          tripStatusTime
          loadStatus
          status
          cargo
          actualDraft
        }
        currentLocation {
          riverLocation {
            code
            mileagePoint
          }
          locationCode
        }
        custodyInfo {
          custody
          boat {
            name
            activeOrder {
              number
              revisionNumber
            }
            destination {
              name
              code
              mileagePoint
            }
            eta
          }
          facility {
            name
          }
          fleet {
            name
          }
        }
        nextDropOffDetails {
          facility {
            name
            code
            mileagePoint
          }
          estimatedDropOffTime
          willBeDroppedAtNextStop
        }
        pickupType
      }
    }
  }
`

export function useLaneBargesQuery(options?: Omit<Urql.UseQueryArgs<LaneBargesQueryVariables>, 'query'>) {
  return Urql.useQuery<LaneBargesQuery, LaneBargesQueryVariables>({ query: LaneBargesDocument, ...options })
}
export const NominationByIdDocument = gql`
  query nominationById($id: NominationId!) {
    nominationById(nominationId: $id) {
      nomination {
        id
        slug
        recordTime
        tboLinkStatus
        tboNumber
      }
      versions {
        id
        slug
        type
        nominationRequest {
          filters {
            lane
            towOrigin
            towDestination
            excludeBargeTypes
            excludeTripStatuses
            excludeTboInfoBarges
            excludeShuttleMoves
            maximumDraft
            expectedDepartureTime
            includeTBOs
            excludeNonNominatableBarges
          }
          configuration {
            goal
            numberOfBarges
            includeBargeIds
            excludeBargeIds
            numberOfEmptyBarges
            boat
            hasTurnboat
            numberOfStrings
            prioritizeHotBarges
          }
        }
        tow {
          efficiencyMetric
          boat {
            name
            boatId
            abbreviation
          }
          barges {
            ...NominatedBargeFragment
          }
          tbnBarges {
            pickupFacility {
              name
              facilityCode
              code
              mileagePoint
            }
            dropOffFacility {
              name
              facilityCode
              code
              mileagePoint
            }
            expectedLoadStatus
          }
          hasTurnboat
          stopsWithMetrics {
            dwellMinutes
            distanceToNextStop
            travelMinutesToNextStop
            stop {
              code
              mileagePoint
            }
          }
        }
        recordTime
        tboSubmissionStatus
        orderRevisionId {
          number
          headerId
          revisionNumber
        }
      }
    }
  }
  ${NominatedBargeFragmentFragmentDoc}
`

export function useNominationByIdQuery(options: Omit<Urql.UseQueryArgs<NominationByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<NominationByIdQuery, NominationByIdQueryVariables>({ query: NominationByIdDocument, ...options })
}
export const NominationsDocument = gql`
  query nominations($ownedBy: OwnedBy) {
    nominations(ownedBy: $ownedBy) {
      id
      lane
      origin {
        code
        name
        mileagePoint
        facilityCode
      }
      destination {
        code
        name
        mileagePoint
        facilityCode
      }
      expectedDepartureTime
      owner {
        cognitoId
        username
      }
      towShape {
        empty
        loaded
        strings
      }
      tboNumber
      tboLinkStatus
      recordTime
    }
  }
`

export function useNominationsQuery(options?: Omit<Urql.UseQueryArgs<NominationsQueryVariables>, 'query'>) {
  return Urql.useQuery<NominationsQuery, NominationsQueryVariables>({ query: NominationsDocument, ...options })
}
export const SettingsDocument = gql`
  query settings {
    lanes {
      id
      label
    }
    hubLikes {
      __typename
      ... on Hub {
        id
        label
        riverLocation {
          code
          mileagePoint
        }
      }
      ... on NonHub {
        id
        label
        riverLocation {
          code
          mileagePoint
        }
      }
    }
    goals {
      id
      label
      description
    }
    tripStatuses {
      id
      label
    }
    boats {
      boatId
      abbreviation
      name
    }
    tboSubmissionStatuses {
      id
      displayName
    }
  }
`

export function useSettingsQuery(options?: Omit<Urql.UseQueryArgs<SettingsQueryVariables>, 'query'>) {
  return Urql.useQuery<SettingsQuery, SettingsQueryVariables>({ query: SettingsDocument, ...options })
}
export const SubmitNominationVersionDocument = gql`
  mutation submitNominationVersion($nominationId: NominationId!, $versionId: NominationVersionId!) {
    submitVersionToTbo(nominationId: $nominationId, nominationVersionId: $versionId) {
      __typename
      ... on SubmitFailure {
        message
      }
    }
  }
`

export function useSubmitNominationVersionMutation() {
  return Urql.useMutation<SubmitNominationVersionMutation, SubmitNominationVersionMutationVariables>(
    SubmitNominationVersionDocument
  )
}
