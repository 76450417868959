import * as R from 'ramda'

import { lanesOriginDestFilteringRecord } from '../../../Domain/Hub'
import { GraphqlBargeType, GraphqlTripStatus, HubLike, HubLikeId, LaneId } from '../../../generated/graphql'
import { defaultTimezone } from '../../../lib/formatters'
import { combineDateAndTime, convertAndFormatDate } from '../../../utils/date'

import { PoolFiltersSelectionFormValues, DepartureTimeFormValues, LaneSelectionFormValues } from './types'

export const calculateMaxDraft = (feet: number | null, inch: number | null): number => (feet ?? 0) * 12 + (inch ?? 0)

export const toBargePoolRequestParameters = (
  laneParameters: LaneSelectionFormValues,
  departureParameters: DepartureTimeFormValues,
  poolFilters: PoolFiltersSelectionFormValues,
  pinnedBarges: string[]
) => {
  const { selectedDate, time } = departureParameters
  const departureDate = combineDateAndTime(selectedDate, time)
  const formattedDate = convertAndFormatDate(departureDate, defaultTimezone)

  const excludeBargeTypes = Object.entries({
    tanks: poolFilters.tanks ? GraphqlBargeType.TankChemical : null,
    openHopper: poolFilters.openHopper ? GraphqlBargeType.HoppOpen : null,
  })
    .filter(([, value]) => value !== null)
    .map(([, value]) => value as GraphqlBargeType)

  const excludeTripStatuses = Object.entries({
    placedToLoad: poolFilters.placedToLoad ? GraphqlTripStatus.PlacedLoad : null,
  })
    .filter(([, value]) => value !== null)
    .map(([, value]) => value as GraphqlTripStatus)

  return {
    laneId: laneParameters.laneId || null,
    originId: laneParameters.origin || null,
    destinationId: laneParameters.destination || null,
    excludeBargeTypes,
    excludeTripStatuses,
    excludeTboInfoBarges: poolFilters.havingTboInfo,
    excludeShuttleMoves: poolFilters.shuttleMoves,
    maxDraft: calculateMaxDraft(poolFilters.feet, poolFilters.inch),
    time: formattedDate,
    includeTBOs: poolFilters.includeTBOs,
    includeBargeIds: pinnedBarges,
  }
}

export const filterOrigins = (
  lane: LaneId | undefined,
  origins: Record<HubLikeId, HubLike>
): Record<HubLikeId, HubLike> => {
  const availableOrigins = lane ? Object.keys(lanesOriginDestFilteringRecord[lane]) : []

  return R.filter(hub => availableOrigins.includes(hub.id), origins)
}

export const filterDestinations = (
  lane: LaneId | undefined,
  origin: HubLikeId | undefined,
  destinations: Record<HubLikeId, HubLike>
): Record<HubLikeId, HubLike> => {
  const availableDestinations = lane && origin ? lanesOriginDestFilteringRecord[lane][origin] || [] : []

  return R.filter(hub => availableDestinations.includes(hub.id), destinations)
}
