import { useCallback, useEffect, useMemo, useState } from 'react'

import * as R from 'ramda'
import { useLocation } from 'wouter'
import { number as YupNumber, object as YupObject, string as YupString } from 'yup'

import { fromBargesQuery, isEmpty, minNumberOfRakes, OverviewBarge } from '../../../Domain/Barge'
import { getExcludedBargeTypes, isExcludingHoppers, isExcludingTanks } from '../../../Domain/BargeType'
import { isLane, shortDropLanes } from '../../../Domain/Lane'
import { excludePlaceToLoadTripStatusIds, isExcludingPlacedToLoad } from '../../../Domain/Trip'
import {
  GoalId,
  HullType,
  type GraphqlNewNominationRequest,
  OverviewNominationVersionType,
  useLaneBargesQuery,
  type GraphqlVersionRequest,
} from '../../../generated/graphql'
import { defaultTimezone } from '../../../lib/formatters'
import useNominationActionsModel from '../../../models/useNominationActionsModel'
import { useNominationRequestBridge } from '../../../providers/NominationRequestBridge'
import { useSettingsContext } from '../../../providers/SettingsProvider'
import { errorToast, successToast } from '../../../ui/Toast/Toast'
import { combineDateAndTime, convertAndFormatDate } from '../../../utils/date'
import { useAuthorizationContext } from '../../Account/AuthorizationContext'

import { collectFormFeedbackData } from './collectFormFeedbackData'
import { createFormData } from './form'
import { createStageNavigation } from './navigation'
import {
  buildDepartureTimeDescription,
  buildIncludeTBOsDescription,
  buildLaneDescription,
  buildMaxDraftDescription,
  buildTowParametersDescription,
  buildVesselDescription,
} from './selectors'
import {
  DepartureTimeFormValues,
  isDepartureTimeSelectionStage,
  isLaneSelectionStage,
  isPoolFiltersSelectionStage,
  isTowParametersSelectionStage,
  isVesselSelectionStage,
  LaneSelectionFormValues,
  NominationFormViewModel,
  NominationStage,
  PoolFiltersSelectionFormValues,
  StageData,
  TowParametersFormValues,
  VesselSelectionFormValues,
} from './types'
import { filterDestinations, filterOrigins, toBargePoolRequestParameters } from './utilities'

import type { VersionedNominationRequest } from '../../../models/models'

const MINIMAL_NR_OF_BARGES = 2

export const collectTotals = (data: TowParametersFormValues, barges: OverviewBarge[]) => {
  const totalNumberOfBarges = R.length(barges)

  const totals = R.reduce(
    (acc, barge) => ({
      empties: acc.empties + (isEmpty(barge) ? 1 : 0),
      rakes: acc.rakes + (barge.barge.hullType === HullType.Rake ? 1 : 0),
    }),
    { empties: 0, rakes: 0 },
    barges
  )

  const totalNumberOfLoaded = totalNumberOfBarges - totals.empties
  const total = Math.max(data.numberOfLoaded, MINIMAL_NR_OF_BARGES) + data.numberOfEmpties

  const remaining = {
    numberOfLoaded: Math.max(totalNumberOfLoaded - data.numberOfLoaded, 0),
    numberOfEmpties: Math.max(totals.empties - data.numberOfEmpties, 0),
    numberOfRakes: Math.max(totals.rakes - minNumberOfRakes(data.numberOfLoaded + data.numberOfEmpties), 0),
    numberOfBarges: Math.max(totalNumberOfBarges - total, 0),
  }

  return {
    totalNumberOfLoaded,
    totalNumberOfEmpties: totals.empties,
    remaining,
    totalNumberOfBarges,
  }
}

export const laneSelectionInitialValues: LaneSelectionFormValues = {
  laneId: undefined,
  origin: undefined,
  destination: undefined,
}

export const laneSelectionValidationSchema = YupObject().shape(
  {
    laneId: YupString().required('Required'),
    origin: YupString().required('Required'),
    destination: YupString().required('Required'),
  },
  []
)

export const includeTBOsValidationSchema = YupObject().shape({
  tboInput: YupNumber().positive('TBO number must be positive').integer('TBO number must be an integer'),
})

export const towParametersValidationSchema = YupObject().shape({
  numberOfLoaded: YupNumber().required('Required').positive('Must be positive'),
  numberOfEmpties: YupNumber().required('Required').positive('Must be positive'),
  numberOfStrings: YupNumber().nullable(),
  goal: YupString().nullable().oneOf(Object.values(GoalId)),
})

export type FormState = {
  currentStage: NominationStage
  isSubmitting: boolean
  laneSelectionForm: LaneSelectionFormValues
  vesselSelectionForm: VesselSelectionFormValues
  departureTimeSelectionForm: DepartureTimeFormValues
  poolFiltersSelectionForm: PoolFiltersSelectionFormValues
  towParametersSelectionForm: TowParametersFormValues
}

const initialNavigationState = {
  stage: NominationStage.LaneSelection,
}

const TBN_BOAT = '2402'

const initialFormState: FormState = {
  currentStage: NominationStage.LaneSelection,
  isSubmitting: false,
  laneSelectionForm: {
    laneId: undefined,
    origin: undefined,
    destination: undefined,
  },
  vesselSelectionForm: {
    boatId: TBN_BOAT,
    hasTurnboat: false,
  },
  departureTimeSelectionForm: {
    selectedDate: null,
    time: null,
  },
  poolFiltersSelectionForm: {
    feet: null,
    inch: null,
    tanks: false,
    openHopper: false,
    havingTboInfo: true,
    placedToLoad: false,
    shuttleMoves: false,
    includeTBOs: [],
  },
  towParametersSelectionForm: {
    numberOfLoaded: 0,
    numberOfEmpties: 0,
    numberOfStrings: null,
    goal: null,
    hotBarges: true,
  },
}

const formStateFromRequest = (request: VersionedNominationRequest): FormState => {
  const { bargeFilters, towConfiguration } = request

  const laneSelectionForm = {
    laneId: bargeFilters.lane,
    origin: bargeFilters.towOrigin,
    destination: bargeFilters.towDestination,
  }
  const vesselSelectionForm = {
    boatId: towConfiguration.boat,
    hasTurnboat: towConfiguration.hasTurnboat,
  }

  const towParametersSelectionForm = {
    numberOfLoaded: towConfiguration.numberOfBarges - towConfiguration.numberOfEmptyBarges,
    numberOfEmpties: towConfiguration.numberOfEmptyBarges,
    numberOfStrings: towConfiguration.numberOfStrings,
    goal: towConfiguration.goal,
    hotBarges: towConfiguration.prioritizeHotBarges,
  }

  const maxDraftFeet = bargeFilters.maximumDraft ? Math.floor(bargeFilters.maximumDraft / 12) : null
  const maxDraftInch = bargeFilters.maximumDraft ? bargeFilters.maximumDraft % 12 : null
  const poolFiltersSelectionForm = {
    feet: maxDraftFeet,
    inch: maxDraftInch,
    tanks: isExcludingTanks(bargeFilters.excludeBargeTypes),
    openHopper: isExcludingHoppers(bargeFilters.excludeBargeTypes),
    havingTboInfo: bargeFilters.excludeTboInfoBarges,
    placedToLoad: isExcludingPlacedToLoad(bargeFilters.excludeTripStatuses),
    shuttleMoves: bargeFilters.excludeShuttleMoves,
    includeTBOs: bargeFilters.includeTBOs,
  }

  const { expectedDepartureTime } = bargeFilters
  const expectedDate = expectedDepartureTime
    ? new Date(expectedDepartureTime.getFullYear(), expectedDepartureTime.getMonth(), expectedDepartureTime.getDate())
    : null
  const expectedTime = expectedDepartureTime
    ? { hours: expectedDepartureTime.getHours(), minutes: expectedDepartureTime.getMinutes() }
    : null
  const departureTimeSelectionForm = {
    selectedDate: expectedDate,
    time: expectedTime,
  }

  return {
    currentStage: NominationStage.LaneSelection,
    isSubmitting: false,
    laneSelectionForm,
    vesselSelectionForm,
    departureTimeSelectionForm,
    poolFiltersSelectionForm,
    towParametersSelectionForm,
  }
}

const toNominationRequestPayload = (
  data: FormState,
  includedBarges: string[],
  excludedBarges: string[]
): GraphqlNewNominationRequest => {
  const { selectedDate, time } = data.departureTimeSelectionForm
  const departureDate = combineDateAndTime(selectedDate, time)
  const formattedDate = convertAndFormatDate(departureDate, defaultTimezone)
  const maxDraft =
    data.poolFiltersSelectionForm.feet || data.poolFiltersSelectionForm.inch
      ? (data.poolFiltersSelectionForm.feet ?? 0) * 12 + (data.poolFiltersSelectionForm.inch ?? 0)
      : null

  return {
    configuration: {
      boat: data.vesselSelectionForm.boatId,
      excludeBargeIds: excludedBarges,
      goal: data.towParametersSelectionForm.goal!,
      hasTurnboat: data.vesselSelectionForm.hasTurnboat,
      includeBargeIds: includedBarges,
      numberOfBarges: data.towParametersSelectionForm.numberOfEmpties + data.towParametersSelectionForm.numberOfLoaded,
      numberOfEmptyBarges: data.towParametersSelectionForm.numberOfEmpties,
      numberOfStrings: data.towParametersSelectionForm.numberOfStrings,
      prioritizeHotBarges: data.towParametersSelectionForm.hotBarges,
    },
    filters: {
      excludeBargeTypes: getExcludedBargeTypes(
        data.poolFiltersSelectionForm.tanks,
        data.poolFiltersSelectionForm.openHopper
      ),
      excludeNonNominatableBarges: true,
      excludeShuttleMoves: data.poolFiltersSelectionForm.shuttleMoves,
      excludeTboInfoBarges: data.poolFiltersSelectionForm.havingTboInfo,
      excludeTripStatuses: data.poolFiltersSelectionForm.placedToLoad ? excludePlaceToLoadTripStatusIds : [],
      expectedDepartureTime: formattedDate,
      includeTBOs: data.poolFiltersSelectionForm.includeTBOs,
      lane: data.laneSelectionForm.laneId!,
      maximumDraft: maxDraft,
      towDestination: data.laneSelectionForm.destination!,
      towOrigin: data.laneSelectionForm.origin!,
    },
  }
}

const toVersionRequestPayload = (
  nominationId: string,
  data: FormState,
  includedBarges: string[],
  excludedBarges: string[]
): GraphqlVersionRequest => {
  return {
    ...toNominationRequestPayload(data, includedBarges, excludedBarges),
    nominationId,
  }
}

const useNominationFormViewModel = (id: string | null): NominationFormViewModel => {
  const {
    clearNominationRequest,
    nominationRequest,
    nominationVersionType,
    nominationId,
    nominationVersionId,
    excludedBarges: getExcludedBarges,
    preselectedBarges: getPreselectedBarges,
  } = useNominationRequestBridge()
  const { createNomination, createVersion } = useNominationActionsModel()

  useEffect(() => {
    if (!id) {
      clearNominationRequest()
    }
  }, [clearNominationRequest, id])

  const [stage, setStage] = useState(initialNavigationState.stage)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const [, navigate] = useLocation()

  // const [laneSelectionForm, changeLaneSelectionForm] = useState(laneSelectionInitialValues)
  // const [vesselSelectionForm, changeVesselSelectionForm] = useState({})
  // const [departureTimeSelectionForm, changeDepartureTimeSelectionForm] = useState({})
  // const [towParametersSelectionForm, changeTowParametersSelectionForm] = useState({})

  const withBanner = !!(id && nominationVersionType === OverviewNominationVersionType.External)
  const initialState = id && nominationRequest ? formStateFromRequest(nominationRequest) : initialFormState
  const [formState, setFormState] = useState<FormState>(initialState)

  const { lanes, hubs, boats, goals } = useSettingsContext()

  const [poolRefetchNeeded, setPoolRefetchNeeded] = useState<boolean>(true)
  const [pinnedBarges, setPinnedBarges] = useState<string[]>(getPreselectedBarges())
  const [excludedBarges, setExcludedBarges] = useState<string[]>(getExcludedBarges())

  const bargePoolParameters = useMemo(() => {
    return toBargePoolRequestParameters(
      formState.laneSelectionForm,
      formState.departureTimeSelectionForm,
      formState.poolFiltersSelectionForm,
      pinnedBarges
    )
  }, [formState, pinnedBarges])

  const [{ data: bargesInPool, fetching: isFetchingBargePool }, refetchBargePool] = useLaneBargesQuery({
    variables: bargePoolParameters,
    pause: !poolRefetchNeeded,
  })

  // refresh the pool in background every minute, transparent for the user
  useEffect(() => {
    const timer = setInterval(() => refetchBargePool({ requestPolicy: 'network-only' }), 60 * 1000)

    return () => clearInterval(timer)
  }, [refetchBargePool])

  const submitAction = useCallback(async () => {
    setIsSubmitting(true)
    const callback = id
      ? () => createVersion(toVersionRequestPayload(id, formState, pinnedBarges, excludedBarges))
      : () => createNomination(toNominationRequestPayload(formState, pinnedBarges, excludedBarges))

    try {
      const { nominationId: newNominationId, versionId } = await callback()
      successToast(`${id ? 'Nomination version' : 'Nomination'} successfully created`)
      setIsSubmitting(false)
      navigate(`/nomination/${newNominationId}/version/${versionId}`)
    } catch (e: any) {
      errorToast(e.message)
      setIsSubmitting(false)
    }
  }, [id, formState, createVersion, createNomination, navigate, pinnedBarges, excludedBarges])

  const updateLaneSelectionForm = useCallback((values: LaneSelectionFormValues) => {
    setPoolRefetchNeeded(true)
    setFormState(prev => ({
      ...prev,
      laneSelectionForm: values,
    }))
  }, [])

  const updateVesselSelectionForm = useCallback((values: VesselSelectionFormValues) => {
    setPoolRefetchNeeded(true)
    setFormState(prev => ({
      ...prev,
      vesselSelectionForm: values,
    }))
  }, [])

  const updateTimeSelectionForm = useCallback((values: DepartureTimeFormValues) => {
    setPoolRefetchNeeded(true)
    setFormState(prev => ({
      ...prev,
      departureTimeSelectionForm: values,
    }))
  }, [])

  const updatePoolFiltersForm = useCallback((values: PoolFiltersSelectionFormValues) => {
    setPoolRefetchNeeded(true)
    setFormState(prev => ({
      ...prev,
      poolFiltersSelectionForm: {
        ...prev.poolFiltersSelectionForm,
        ...values,
      },
    }))
  }, [])

  const updateTowParametersSelectionForm = useCallback((values: TowParametersFormValues) => {
    setPoolRefetchNeeded(false)
    setFormState(prev => ({
      ...prev,
      towParametersSelectionForm: {
        ...prev.towParametersSelectionForm,
        ...values,
      },
    }))
  }, [])

  const currentStage = useMemo(() => {
    return {
      stage,
    }
  }, [stage])

  const laneSelectionInitParams = useMemo(() => {
    const { laneId, origin } = formState.laneSelectionForm
    const origins = filterOrigins(laneId, hubs)
    const destinations = filterDestinations(laneId, origin, hubs)

    return {
      lanes,
      origins,
      destinations,
    }
  }, [formState.laneSelectionForm, lanes, hubs])

  const vesselSelectionInitParams = useMemo(
    () => ({
      boats,
    }),
    [boats]
  )

  const { getUserInfo } = useAuthorizationContext()

  const exportFormFeedback = useCallback(() => {
    const userInfo = getUserInfo()

    if (!userInfo) {
      console.error('User info is not available')
      return
    }

    collectFormFeedbackData(
      userInfo,
      formState.laneSelectionForm,
      formState.vesselSelectionForm,
      formState.departureTimeSelectionForm,
      formState.poolFiltersSelectionForm,
      formState.towParametersSelectionForm,
      bargesInPool ? fromBargesQuery(bargesInPool.lanes[0].barges) : [],
      new Date()
    )
  }, [formState, bargesInPool, getUserInfo])

  const handlePinnedBargesUpdate = useCallback((pinned: string[]) => {
    setPoolRefetchNeeded(false)
    setPinnedBarges(pinned)
  }, [])

  const bargePool = useMemo(
    () => ({
      lane: formState.laneSelectionForm.laneId,
      origin: formState.laneSelectionForm.origin,
      destination: formState.laneSelectionForm.destination,
      barges: bargesInPool ? fromBargesQuery(bargesInPool.lanes[0].barges) : [],
      isFetching: isFetchingBargePool,
      pinnedBarges,
      setPinnedBarges: handlePinnedBargesUpdate,
      excludedBarges,
      setExcludedBarges,
      exportFormFeedback,
    }),
    [
      formState,
      bargesInPool,
      isFetchingBargePool,
      pinnedBarges,
      excludedBarges,
      exportFormFeedback,
      handlePinnedBargesUpdate,
    ]
  )

  const towParametersInitParams = useMemo(() => {
    const goalOptions = R.mapObjIndexed((goal, goalId) => {
      let isDisabled
      switch (goalId) {
        case GoalId.LinehaulTurnTime:
          isDisabled = !(
            formState.departureTimeSelectionForm.selectedDate ||
            (formState.departureTimeSelectionForm.selectedDate && formState.departureTimeSelectionForm.time)
          )
          break
        case GoalId.ShortDropTow:
          isDisabled = !(
            isLane(formState.laneSelectionForm.laneId) && shortDropLanes.includes(formState.laneSelectionForm.laneId)
          )
          break
        default:
          isDisabled = false
          break
      }
      return {
        label: goal.label,
        isDisabled,
      }
    }, goals)

    return {
      goals: goalOptions,
      currentGoal: formState.towParametersSelectionForm.goal,
      barges: bargePool.barges,
    }
  }, [
    formState.departureTimeSelectionForm.selectedDate,
    formState.departureTimeSelectionForm.time,
    formState.towParametersSelectionForm.goal,
    formState.laneSelectionForm.laneId,
    goals,
    bargePool.barges,
  ])

  const hotBargeCount = useMemo(
    () => towParametersInitParams.barges.filter(b => b.isHot).length,
    [towParametersInitParams.barges]
  )

  const backHandler = useCallback(() => {
    const backUrl =
      nominationId && nominationVersionId
        ? `/nomination/${nominationId}/version/${nominationVersionId}`
        : '/nominations'
    navigate(backUrl)
  }, [nominationId, nominationVersionId, navigate])

  const stages: Record<NominationStage, StageData<any, any, any>> = useMemo(
    () => ({
      [NominationStage.LaneSelection]: {
        isSelected: isLaneSelectionStage(currentStage),
        form: createFormData(formState.laneSelectionForm, updateLaneSelectionForm),
        summary: { description: buildLaneDescription(formState.laneSelectionForm, lanes, hubs) },
        initParameters: laneSelectionInitParams,
        actions: createStageNavigation(NominationStage.LaneSelection, setStage),
      },
      [NominationStage.VesselSelection]: {
        isSelected: isVesselSelectionStage(currentStage),
        form: createFormData(formState.vesselSelectionForm, updateVesselSelectionForm),
        summary: {
          description: buildVesselDescription(formState.vesselSelectionForm, boats),
        },
        initParameters: vesselSelectionInitParams,
        actions: createStageNavigation(NominationStage.VesselSelection, setStage),
      },
      [NominationStage.DepartureTimeSelection]: {
        isSelected: isDepartureTimeSelectionStage(currentStage),
        form: createFormData(formState.departureTimeSelectionForm, updateTimeSelectionForm),
        summary: {
          description: buildDepartureTimeDescription(
            formState.departureTimeSelectionForm.selectedDate,
            formState.departureTimeSelectionForm.time
          ),
        },
        actions: createStageNavigation(NominationStage.DepartureTimeSelection, setStage),
      },
      [NominationStage.PoolFiltersSelection]: {
        isSelected: isPoolFiltersSelectionStage(currentStage),
        form: createFormData(formState.poolFiltersSelectionForm, updatePoolFiltersForm),
        summary: {
          description: [
            buildMaxDraftDescription(formState.poolFiltersSelectionForm.feet, formState.poolFiltersSelectionForm.inch),
            buildIncludeTBOsDescription(formState.poolFiltersSelectionForm.includeTBOs || []),
          ],
        },
        actions: createStageNavigation(NominationStage.PoolFiltersSelection, setStage),
      },
      [NominationStage.TowParametersSelection]: {
        isSelected: isTowParametersSelectionStage(currentStage),
        form: createFormData(formState.towParametersSelectionForm, updateTowParametersSelectionForm),
        summary: {
          description: buildTowParametersDescription(formState.towParametersSelectionForm, hotBargeCount),
        },
        initParameters: towParametersInitParams,
        actions: createStageNavigation(NominationStage.TowParametersSelection, setStage),
      },
    }),
    [
      currentStage,
      formState,
      lanes,
      hubs,
      boats,
      laneSelectionInitParams,
      towParametersInitParams,
      updateLaneSelectionForm,
      updateVesselSelectionForm,
      updateTimeSelectionForm,
      updatePoolFiltersForm,
      updateTowParametersSelectionForm,
      hotBargeCount,
      vesselSelectionInitParams,
    ]
  )

  return {
    currentStage,
    isSubmitting,
    stages,
    bargePool,
    withExternalVersionBanner: withBanner,
    submit: submitAction,
    backHandler,
  }
}

export default useNominationFormViewModel
