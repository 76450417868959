import React, { type FC } from 'react'

import DateTimeSelector from '../../../components/Header/TimeSelector/DateTimeSelector'
import { Time } from '../../../components/Header/TimeSelector/TimeInput'

import FormOrSummary from './FormOrSummary'

import type { StageData } from './types'

const DateTimeSelectionForm: FC<{
  day: Date | null
  time?: Time | null
  onChange: (day: Date | null, time: Time | null) => void
}> = ({ day, time, onChange }) => {
  const handleChange = (newDay: Date | null, newTime: Time | null) => {
    const updatedDay = newDay
    const updatedTime = newDay ? newTime : null
    onChange(updatedDay, updatedTime)
  }

  return (
    <div>
      <DateTimeSelector day={day} time={time} onChange={handleChange} isDisabled={false} />
    </div>
  )
}

const DepartureDateTimeSelectionSummary: FC<{ description: string }> = ({ description }) => {
  return <div>{description}</div>
}

const DepartureDateTimeSelectionBlock: FC<StageData<any, any, any>> = ({ isSelected, actions, form, summary }) => {
  const { data, onChange } = form
  const { description } = summary

  const handleDepartureTimeChange = (day: Date | null, time: Time | null) => {
    const updatedDay = day
    const updatedTime = day ? time : null
    onChange({
      selectedDate: updatedDay,
      time: updatedTime,
    })
  }

  return (
    <FormOrSummary
      title="Departure Time"
      isSelected={isSelected}
      actions={actions}
      form={<DateTimeSelectionForm day={data.selectedDate} time={data.time} onChange={handleDepartureTimeChange} />}
      summary={<DepartureDateTimeSelectionSummary description={description} />}
    />
  )
}

export default DepartureDateTimeSelectionBlock
