import React, { useState, useMemo, FC, ChangeEvent } from 'react'

import { Combobox } from '@headlessui/react'
import classNames from 'classnames'

import {
  ComboboxWrapper,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  ComboboxInput,
} from '../../../ui/Combobox/Combobox'

import styles from './ComboboxSelector.module.scss'

type ComboboxSelectorProps = {
  value?: string
  handleChange: (item: string) => void
  items: Record<string, any>
  displayValue: (itemId: string | undefined) => string
  placeholder: string
  IconComponent: React.ElementType
  isDisabled?: boolean
}

const ComboboxSelector: FC<ComboboxSelectorProps> = ({
  value,
  handleChange,
  items,
  displayValue,
  placeholder,
  IconComponent,
  isDisabled = false,
}) => {
  const [query, setQuery] = useState('')

  const filteredItems = useMemo(() => {
    return query === ''
      ? Object.entries(items)
      : Object.entries(items).filter(([, val]) => (val.label || val).toLowerCase().includes(query.toLowerCase()))
  }, [items, query])

  const handleInputChange = ({ target: { value: newValue } }: ChangeEvent<HTMLInputElement>) => setQuery(newValue)

  return (
    <ComboboxWrapper className={styles.menu}>
      <Combobox disabled={isDisabled} value={value ?? ''} onChange={handleChange}>
        <ComboboxInput className={styles.comboboxInput}>
          <label>
            <IconComponent />
            <Combobox.Input placeholder={placeholder} displayValue={displayValue} onChange={handleInputChange} />
            <ComboboxButton />
          </label>
        </ComboboxInput>
        <ComboboxOptions afterLeave={() => setQuery('')}>
          {filteredItems.map(([key, val]) => {
            const isOptionDisabled = val.isDisabled || false
            return (
              <ComboboxOption
                className={classNames(styles.menuItem, { [styles.isDisabled]: isOptionDisabled })}
                key={key}
                value={isOptionDisabled ? '' : key}
                aria-disabled={isOptionDisabled}>
                {val.label || val}
              </ComboboxOption>
            )
          })}
        </ComboboxOptions>
      </Combobox>
    </ComboboxWrapper>
  )
}

export default ComboboxSelector
